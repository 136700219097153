import React from "react";
import { Button } from "primereact/button";
import { exportExcel } from '@utils/export.js';

export default function ExportExcelButton({value, fileName}) {


const handleOnClick = () => {
    exportExcel(value, fileName);
  };

  return (
    <Button
      type='button'
      icon='pi pi-file-excel'
      label='Excel'
      className='btn-excel'
      onClick={handleOnClick}
      data-pr-tooltip='XLS'
    />
  );
}
