import supabase from "../config/supabase";

export const authValid = async () => {
  const { data } = await supabase.auth.getSession();
  if (data.session === null) {
    return null;
  } else {
    return data.session;
  }
};

export const isUserLoggedIn = async () => {
  const { data, error } = await supabase.auth.getSession();
  if(error) throw new Error("Error in isUserLoggedIn")
  if (data.session === null) {
    return false;
  } else return true;
};
