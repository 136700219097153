export const getActiveIndexTab = (storageName, setActiveIndex) => {
  if (localStorage.getItem(storageName) === null) {
    localStorage.setItem(storageName, 0);
    setActiveIndex(0);
  } else {
    let nr = Number(localStorage.getItem(storageName));
    setActiveIndex(Number(nr));
  }
};

export const setActiveIndexTab = (storageName, activeIndex) => {
  localStorage.setItem(storageName, activeIndex);
};
