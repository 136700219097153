import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import supabase from "../config/supabase";
import { authValid } from "../utils/auth";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { checkIfCurrentUserExistsInTable } from "@db/currentUser.js";
import "../styles/animation.css";

export default function Login() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("input-group");
  const [emailMessage, setEmailMessage] = useState("");
  const [errorPassword, setErrorPassword] = useState("input-group");
  const [emailsArray, setEmailsArray] = useState([]);

  const navigation = useNavigate();
  const [displayLogin, setDisplayLogin] = useState(true);
  const changeDisplay = () => {
    if (displayLogin) {
      setDisplayLogin(false);
    } else {
      setDisplayLogin(true);
    }
  };

  const checkIfEmailExists = () => {
    let emailArrayValue;
    for (let i = 0; i < emailsArray.length; i++) {
      emailArrayValue = emailsArray[i].email;
    }
    console.log(emailArrayValue.includes(magicLinkEmail));
    if (!emailsArray.includes(magicLinkEmail)) {
      console.log("this email exists");
      sendMagicLink();
    } else {
      console.log("this email does not exist");
      setErrorMagicStyling("input-group error");
      setEmailMessage("Invalid email, it does not exist in our database");
      setErrorMagicLink("Invalid email, it is not regiered in our database");
    }
  };

  useEffect(() => {
    const checkIfUserLoggedIn = async () => {
      const logginIn = await authValid();
      if (logginIn) {
        navigation("/profile");
      }
    };
    const listAllCurrentEmails = async () => {
      const { data, error } = await supabase.from("users").select("email");
      if (error) {
        console.log("error", error);
        return;
      } else {
        // console.log(data);
        setEmailsArray(data);
      }
    };
    checkIfUserLoggedIn();
    listAllCurrentEmails();
  }, []);

  const checkIfEmailExist = async () => {
    const { data } = await supabase
      .from("users")
      .select()
      .eq("email", loginEmail);
    if (data.length === 0) {
      console.log("the email: <" + loginEmail + "> does not exist");
      setErrorEmail("input-group error");
      setEmailMessage("Invalid email, it does not exist in our database");
    } else {
      console.log("data in checkIfEmailExist");

      setErrorEmail("input-group success");
      setEmailMessage("Valid email");
    }
  };

  const checkIfPasswordCorrect = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email: loginEmail,
      password: loginPassword,
    });
    if (error) {
      setErrorPassword("input-group error");
      console.log("got an error");
      console.log(error.message);
    } else {
      
      if (await checkIfCurrentUserExistsInTable())
        window.location.href = "/profile";
      else window.location.href = "/form";
    }
  };

  const login = () => {
    checkIfEmailExist();
    checkIfPasswordCorrect();
  };

  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [showResetPassword, setShowResetPassword] = useState({
    display: "none",
  });

  const facebookSubmit = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "facebook",
      redirectTo: "https://camp.cbmbc.org/form",
    });
    if (error) {
      console.log(error);
    } else {
      
      navigation("/form");
    }
  };

  const googleSubmit = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      redirectTo: "https://camp.cbmbc.org/form",
    });
    if (error) {
      console.log(error);
    } else {
      navigation("/form");
    }
  };

  //Reset Password ________________________________________________________________

  const [resetPasswordShow, setResetPasswordShow] = useState(0);
  const [isPasswordResetSent, setIsPasswordResetSent] = useState(0);

  const sendResetPassword = async () => {
    const { error } = await supabase.auth.resetPasswordForEmail(
      resetPasswordEmail,
      {
        redirectTo: "https://camp.cbmbc.org/password-reset",
      }
    );
    if (
      error &&
      error.message === "Unable to validate email address: invalid format"
    ) {
      console.log("error", error.message);
      setResetPasswordShow(2);
      setIsPasswordResetSent(1);
      return;
    } else if (error) {
      console.log("error 2", error.message);
      setResetPasswordShow(2);
      setIsPasswordResetSent(3);
    } else {
      console.log("Accepted");
      setResetPasswordShow(2);
      setIsPasswordResetSent(0);
    }
  };

  const resetPasswordComponent = () => {
    return (
      <>
        <p className='note'>
          If you easily forget your password we recommend you to use magic link
          method, as it does not require you to remember your password. Rather
          you will receive a link to your email address and you can log in with
          that link.
        </p>
        <p className='navigate-text-left'>
          <a
            onClick={() => {
              setShowResetPassword({ display: "none" });
              changeDisplay();
            }}
          >
            Use the magic link method instead
          </a>
        </p>

        <p>
          If you still want to reset your password, please enter your email
          address and we will send you a link to reset your password.
        </p>
        <label htmlFor='email'>Email</label>
        <input
          type='email'
          onChange={(event) => setResetPasswordEmail(event.target.value)}
        ></input>
        <Button
          label='Send link'
          onClick={() => {
            setResetPasswordShow(1);
            setTimeout(() => {
              sendResetPassword();
            }, 2000);
          }}
        />
      </>
    );
  };

  const spinnerLoadingComponent = () => {
    return (
      <ProgressSpinner
        className='spinner-styling'
        strokeWidth='8'
        animationDuration='.5s'
      />
    );
  };

  const resultResetPasswordComponent = () => {
    switch (isPasswordResetSent) {
      case 0: {
        return (
          <>
            <p>
              We have sent you an email with a link to reset your password.
              Please check your email and click on the link to reset your
              password 😎
            </p>
            <p>
              If you do not receive an email, please check your spam folder.
            </p>
          </>
        );
      }
      case 1: {
        return (
          <>
            <p>
              Sorry we could not find your email address in our database. Please
              check your email address and try again 😕
            </p>
            <Button
              label='Try again'
              icon='pi pi-angle-left'
              onClick={() => {
                setResetPasswordShow(0);
              }}
              className='backbtn p-button-rounded'
            />
          </>
        );
      }
      default: {
        return (
          <>
            <p>
              Something went wrong. please check your internet connection and
              try again 😬
            </p>
            <Button
              label='Try again'
              icon='pi pi-angle-left'
              onClick={() => {
                setResetPasswordShow(0);
              }}
              className='backbtn p-button-rounded'
            />
          </>
        );
      }
    }
  };

  const resetPasswordDisplay = () => {
    switch (resetPasswordShow) {
      case 0: {
        return resetPasswordComponent();
      }
      case 1: {
        return spinnerLoadingComponent();
      }
      case 2: {
        return resultResetPasswordComponent();
      }
      default: {
        return spinnerLoadingComponent();
      }
    }
  };

  //Magic Link ________________________________________________________________
  const [magicLinkEmail, setMagicLinkEmail] = useState("");
  const [errorMagicLink, setErrorMagicLink] = useState("");
  const [errorMagicStyling, setErrorMagicStyling] = useState("input-group");
  const sendMagicLink = async () => {
    setErrorEmail("input-group success");
    setErrorMagicLink("");
    setErrorMagicLink("");
    const { data, error } = await supabase.auth.signInWithOtp({
      email: magicLinkEmail,
      options: {
        emailRedirectTo: "https://camp.cbmbc.org/profile",
      },
    });
    if (error) {
      console.log("error", error);
      return;
    } else {
      console.log("data", data);
      setSuccessBoxVisible(true);
    }
  };
  const magicLinkComponent = () => {
    return (
      <div className='sign-up-container'>
        <div className='sign-up-form'>
          <h1 className='sign-up-title'>Magic link</h1>
          <p className='ptext-magic-link'>
            Enter your email address and we will send you a link to log in. No
            need to reset and remember your password.
          </p>
          <div className={errorMagicStyling}>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              name='email'
              onChange={(event) => setMagicLinkEmail(event.target.value)}
            ></input>
            <span className='msg'>{errorMagicLink}</span>
          </div>
          <Button
            label='Send magic link'
            icon='pi pi-send'
            iconPos='left'
            className='magicbtn p-button-raised p-button-rounded'
            onClick={checkIfEmailExists}
          ></Button>
          <Button
            label='Go back'
            icon='pi pi-angle-left'
            onClick={changeDisplay}
            className='backbtn p-button-rounded'
          />
        </div>
      </div>
    );
  };
  const [successBoxVisible, setSuccessBoxVisible] = useState(false);

  const loginComponent = () => {
    return (
      <div className='login-container'>
        <h1 className='container-title'>Log in</h1>
        <div className='form'>
          <div className={errorEmail}>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              name='email'
              id='emailLogin'
              onChange={(event) => {
                setLoginEmail(event.target.value);
              }}
            ></input>
            <span className='msg'>{emailMessage}</span>
          </div>
          <div className={errorPassword}>
            <label htmlFor='password'>Password</label>
            <input
              type='password'
              name='password'
              id='password'
              onChange={(event) => {
                setLoginPassword(event.target.value);
              }}
            ></input>
            <span className='msg'>Incorrect password</span>
          </div>
          <span className='navigate-text box-space-between'>
            <a onClick={() => navigation("/signup")} className="text-left">
              New to this? Sign up here
            </a>
            <a onClick={() => setShowResetPassword({ display: "flex" })}>
              Forgot password?
            </a>
          </span>
          <Button
            onClick={login}
            className='login-button p-button-raised p-button-rounded'
            label='Login'
          ></Button>
        </div>
        <h1 className='or'>OR</h1>
        <div className='login-provider-form'>
          <Button
            label='Magic link'
            className='magicbtn p-button-raised p-button-rounded'
            icon='pi pi-unlock
                  px-1'
            onClick={changeDisplay}
          />
          <Button
            label='Facebook'
            className='facebookbtn p-button-rounded facebook'
            icon='pi pi-facebook px-1'
            iconPos='left'
            onClick={facebookSubmit}
          ></Button>
          <Button
            label='Google'
            className='googlebtn p-button-rounded'
            icon='pi pi-google px-1'
            iconPos='left'
            onClick={googleSubmit}
            // disabled={true}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='full-screen-container'>
        {displayLogin ? loginComponent() : magicLinkComponent()}
        <div className='modula-background' style={showResetPassword}>
          <div className='modula-content'>
            <div className='modula-header'>
              <h1>Reset password</h1>
              <Button
                icon='pi pi-times'
                severity='danger'
                aria-label='Cancel'
                className='btn-exit'
                onClick={() => {
                  setResetPasswordShow(0);
                  setShowResetPassword({ display: "none" });
                }}
              />
            </div>
            <div className='modula-body'>{resetPasswordDisplay()}</div>
          </div>
        </div>
      </div>

      <Dialog
        header='Sucessfully sent magic link '
        visible={successBoxVisible}
        onHide={() => setSuccessBoxVisible(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <p className='m-0' style={{ marginBottom: "20px" }}>
          An email has been sent to you, please check your inbox and click on
          the link to log in into your account.
        </p>
      </Dialog>
    </>
  );
}
