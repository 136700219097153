import React, { useState, useRef, Children, cloneElement } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { updateDataUsers } from "@db/update";
import { checkVariable } from "@utils/checkVariables";

export default function EditButton({
  uuid,
  updateData,
  children,
  reload,
  setNull,
}) {
  const [value, setValue] = useState(null);
  const [visible, setVisible] = useState(false);
  const toastTopCenter = useRef(null);
  
  const handleSaveChange = async () => {
    if (!checkVariable(value) && !setNull)
    
      return showMessage(
        "Value is not selected",
        toastTopCenter,
        "error",
        "Error"
      );

    //Problem with dropdown and placeholder using primre react
    //so set the value to null if the value is undefined
    let _value = value;
    if (value === undefined) _value = null;

    const newData = Object.keys(updateData).reduce((acc, key) => {
      acc[key] = _value;
      return acc;
    }, {});

    const response = await updateDataUsers(uuid, newData);
    if (response) {
      setVisible(false);
      if (reload) window.location.reload();
      return;
    }
    showMessage("Could not save", toastTopCenter, "error", "Error");
  };

  const showMessage = (detail, ref, severity, summary) => {
    ref.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  // Enhance children with additional props
  const enhancedChildren = Children.map(children, (child) =>
    cloneElement(child, { onSelectChange: setValue })
  );

  return (
    <>
      <Button
        label='Edit'
        icon='pi pi-pencil'
        onClick={() => setVisible(true)}
      />
      <Dialog
        visible={visible}
        modal
        onHide={() => setVisible(false)}
        className='dark-bg'
        showHeader={false}
      >
        <div className='box-column box-responsive'>
          {enhancedChildren}
          <div className='box-row'>
            <Button
              label='Save'
              icon='pi pi-check'
              onClick={handleSaveChange}
              autoFocus
            />
            <Button
              label='Cancel'
              icon='pi pi-times'
              onClick={() => setVisible(false)}
              className='btn-delete'
            />
          </div>
        </div>
      </Dialog>
      <Toast ref={toastTopCenter} position='top-center' />
    </>
  );
}
