import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import "@styles/display.css";
import { checkVariable } from "@utils/checkVariables.js";
import { faker } from "@faker-js/faker";
import { calculateTextColor, rgbToHex } from "@utils/colors.js";

export default function Display({ data, categoryOrder }) {
  const [dataList, setDataList] = useState([]);

  const populateDataListWithExamples = (length) => {
    let arr = [];
    for (let i = 1; i <= length; i++) {
      let dataArray = [];
      for (let j = 1; j <= 5; j++) {
        dataArray.push({
          name: faker.name.findName(),
          category: faker.helpers.arrayElement(categoryOrder),
        }); // Added category for example
      }
      arr.push({ title: "Table " + i, list: dataArray });
    }
    setDataList(arr);
  };

  useEffect(() => {
    const populateData = () => {
      if (checkVariable(data)) {
        setDataList(data);
      } else {
        populateDataListWithExamples(7);
      }
    };

    populateData();
  }, [data]);

  useEffect(() => {
    const cards = document.querySelectorAll(".display-card");
    cards.forEach((card) => {
      let backgroundColor = card.style.backgroundColor || "#ffffff";

      if (backgroundColor.includes("rgb"))
        backgroundColor = rgbToHex(backgroundColor);

      const subtitle = card.querySelector(".p-card-subtitle");
      if (subtitle) {
        subtitle.style.color = calculateTextColor(backgroundColor).hex;
      }
    });
  }, [dataList]);

  const sortAndGroupByCategory = (list) => {
    let sortedList = [];
    let count = 1;
  
    categoryOrder.forEach((category) => {
      let itemsInCategory = list.filter((item) => item.category === category);
  
      // Sort itemsInCategory alphabetically by 'name'
      itemsInCategory.sort((a, b) => a.name.localeCompare(b.name));
  
      if (itemsInCategory.length > 0) {
        sortedList.push({ isHeader: true, name: category });
  
        itemsInCategory.forEach((item) => {
          sortedList.push({ ...item, count: count++ });
        });
      }
    });
  
    return sortedList;
  };

  return (
    <>
      <div className='display-container'>
        {dataList &&
          dataList.map((cardData, i) => {
            if (i === 0 && cardData.list.length === 0) return null;

            let processedList = cardData.list;
            if (checkVariable(categoryOrder)) {
              processedList = sortAndGroupByCategory(cardData.list);
            }

            const backgroundColor = cardData.backgroundColor || "#ffffff";
            const textColor = calculateTextColor(backgroundColor).hex;
            const cardStyle = { backgroundColor, color: textColor };

            if (i === 0) {
              cardStyle.overflow = "auto";
              cardStyle.maxHeight = "500px";
            }

            return (
              <Card
                key={i}
                title={cardData.title ? cardData.title : ""}
                subTitle={cardData.subTitle ? cardData.subTitle : ""}
                className='display-card'
                style={cardStyle}
              >
                <ul>
                  {processedList.map((item, index) =>
                    item.isHeader ? (
                      <li
                        key={index}
                        className='display-header'
                        style={{ color: textColor, listStyleType: "none" }}
                      >
                        {item.name}
                      </li>
                    ) : (
                      <li key={index} value={item.count}>
                        {item.name}
                      </li>
                    )
                  )}
                </ul>
              </Card>
            );
          })}
      </div>
    </>
  );
}
