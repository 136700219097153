import React, { useEffect } from "react";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import About from "./pages/About";
import Pricing from "./pages/Pricing";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import Form from "./pages/Form";
import Campers from "./pages/Campers";
import PrivateRoutes from "./utils/PrivateRoutes";
import Admin from "./pages/Admin";
import PasswordReset from "./pages/PasswordReset";
import AdminRoutes from "./utils/AdminRoutes";
import Test from "./pages/Test";
import Checkout from "./pages/Checkout";
import Payment from "./pages/Payment";
import TestRoute from "./utils/TestRoute.js";
import Contact from "./pages/Contact.js";
import TermsOfService from "./pages/TermsOfService.js";
import NotFoundPage from "./pages/NotFoundPage";
import PDFViewer from "./pages/PDFViewer.js";
import {
  DiscordWebhookVisitorDetected,
  DiscordWebhookLoginDetected,
} from "@utils/discordWebhooks.js";
import { DeveloperMode } from "./config/DeveloperMode.js";
import { isUserLoggedIn } from "@utils/auth.js";
import { unsubscribeAllChannels } from "@db/subscribe.js";
import PDF from "./docs/Schedule.pdf"

function App() {
  useEffect(() => {
    const fetchUser = async () => {
      /**
       * https://stackoverflow.com/a/66758292/16003472
       * Clear local storage after 1 hour
       */
      if (DeveloperMode()) return;
      let hours = 1;
      let calcHours = hours * 60 * 60 * 1000;
      let now = new Date().getTime();
      let setupTime = localStorage.getItem("setupTime");
      if (setupTime == null || now - setupTime > calcHours) {
        if (await isUserLoggedIn()) await DiscordWebhookLoginDetected();
        else await DiscordWebhookVisitorDetected();
        localStorage.setItem("setupTime", now);
      }
    };
    fetchUser();
  }, []);

  const nav = useNavigate();

  useEffect(() => {
    return () => {
      unsubscribeAllChannels();
    };
  }, [nav]);

  return (
    <>
      <NavBar />
      <Routes>
        <Route element={<AdminRoutes />}>
          {" "}
          {/*All nested protected Admin pages */}
          <Route path='/admin' element={<Admin />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          {" "}
          {/*All nested protected pages */}
          <Route path='/profile' element={<Profile />} />
          <Route path='/campers' element={<Campers />} />
          <Route path='/form' element={<Form />} />
          <Route path='/addCamper' element={<Form addCamperPage />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/payment' element={<Payment />} />
          <Route
            path='/schedule'
            element={<PDFViewer pdf={PDF} />}
          />
        </Route>
        <Route element={<TestRoute />}>
          <Route path='/test' element={<Test />} />
        </Route>
        <Route path='/' element={<Home />} exact />
        <Route path='/about' element={<About />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Register />} />
        <Route path='/password-reset' element={<PasswordReset />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/terms-of-service' element={<TermsOfService />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
