import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getTransportationInfo } from "@db/users.js";
import PieChart from "@components/charts/PieChart";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { exportPDF } from "@utils/export.js";
import { formatDateToday } from "@utils/dateTime.js";
import { Divider } from "primereact/divider";
import { Accordion } from "primereact/accordion";
import { AccordionTab } from "primereact/accordion";
import ExportPDFButton from "@components/buttons/ExportPDFButton.js";

export default function TransportAdmin() {
  const [totalArrival, setTotalArrival] = useState({});
  const [totalDeparture, setTotalDeparture] = useState({});
  const [dataChartArrival, setDataChartArrival] = useState([]); // [[label, value, color], [label, value, color], [label, value, color]]
  const [dataChartDeparture, setDataChartDeparture] = useState([]); // [[label, value, color], [label, value, color], [label, value, color]
  const [arrivalBusList, setArrivalBusList] = useState([]);
  const [departureBusList, setDepartureBusList] = useState([]);
  const [visibleArrival, setVisibleArrival] = useState(false);
  const [visibleDeparture, setVisibleDeparture] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const data = await getTransportationInfo();
      if (data.arrival.car.total === undefined || data.arrival === null) return;
      setTotalArrival({
        car: data.arrival.car.total,
        bus: data.arrival.bus.total,
        public_transport: data.arrival.public_transport.total,
      });
      setTotalDeparture({
        car: data.departure.car.total,
        bus: data.departure.bus.total,
        public_transport: data.departure.public_transport.total,
      });

      //sort alphabetically
      // Sort the bus names alphabetically and map them to objects for the DataTable
      setArrivalBusList(
        data.arrival.bus.names
          .sort((a, b) => a.localeCompare(b))
          .map((name) => ({ field: name }))
      );
      setDepartureBusList(
        data.departure.bus.names
          .sort((a, b) => a.localeCompare(b))
          .map((name) => ({ field: name }))
      );

      setPieChartData(data.arrival, setDataChartArrival);
      setPieChartData(data.departure, setDataChartDeparture);
    };

    const setPieChartData = (data, set) => {
      if (data.car === null) return;
      const pieChartData = [
        {
          label: "Car",
          value: data.car.total,
          color: "#FF6384",
          hover: "#FF6384",
        },
        {
          label: "Bus",
          value: data.bus.total,
          color: "#36A2EB",
          hover: "#36A2EB",
        },
        {
          label: "Public transport",
          value: data.public_transport.total,
          color: "#0DDF89",
          hover: "#2DF9A6",
        },
      ];
      set(pieChartData);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className='center-grid box-padding'>
        <p className='text-center text-medium'>Transportation</p>
        <h3 className='text-center text-medium'>
          Showing only registered campers
        </h3>
      </div>
      <Accordion activeIndex={0}>
        <AccordionTab header='Arrival'>
          <div className='center-grid width-100'>
            <h2>Arrival</h2>
            <DataTable
              value={dataChartArrival}
              className='center-grid width-100'
            >
              <Column field='label' header='Transport' />
              <Column field='value' header='Total' />
            </DataTable>
            <PieChart dataObject={dataChartArrival} />
            <div className='btn-single-space'>
              <Button
                label='Show Bus list'
                onClick={() => setVisibleArrival(true)}
              />
            </div>
          </div>
        </AccordionTab>
        <Divider />
        <AccordionTab header='Departure'>
          <div className='center-grid width-100'>
            <h2>Departure</h2>
            <DataTable
              value={dataChartDeparture}
              className='center-grid width-100'
            >
              <Column field='label' header='Transport' />
              <Column field='value' header='Total' />
            </DataTable>
            <PieChart dataObject={dataChartDeparture} />
            <div className='btn-single-space'>
              <Button
                label='Show Bus list'
                onClick={() => setVisibleDeparture(true)}
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>

      <Dialog
        header='Bus arrival list'
        maximizable
        visible={visibleArrival}
        style={{ width: "50vw" }}
        onHide={() => setVisibleArrival(false)}
      >
        <ExportPDFButton value={arrivalBusList} fileName='arrivalBusList_' />
        <DataTable value={arrivalBusList}>
          <Column field='field' header='Name' />
        </DataTable>
      </Dialog>
      <Dialog
        header='Bus departure list'
        maximizable
        visible={visibleDeparture}
        style={{ width: "50vw" }}
        onHide={() => setVisibleDeparture(false)}
      >
        <ExportPDFButton
          value={departureBusList}
          fileName='departureBusList_'
        />
        <DataTable value={departureBusList}>
          <Column field='field' header='Name' />
        </DataTable>
      </Dialog>
    </>
  );
}
