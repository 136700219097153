import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
export default function NotAvailable({ title, message }) {
  const nav = useNavigate();
  return (
    <section className='center-grid white-background box-full-height'>
      <div className='text-center'>
        <h1>{title}</h1>
        <p>{message}</p>
        <Button
          onClick={() => nav("/profile")}
          label='Go back'
          icon='pi pi-arrow-left'
          className='p-button-raised p-button-rounded p-button-text'
        /> 
      </div>
    </section>
  );
}
