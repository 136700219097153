import { useState } from "react";
import { SelectButton } from "primereact/selectbutton";

export default function SelectGender({onSelectChange}) {
    const [gender, setGender] = useState("");

  const selectGender = [
    { label: "Male 🙋‍♂️", value: "Male" },
    { label: "Female 🙋‍♀️", value: "Female" },
  ];

    const handleSelectChange = (e) => {
        setGender(e.value);
        onSelectChange(e.value); // Call the callback function with the selected value
    };

  return (
    <SelectButton
      value={gender}
      options={selectGender}
      onChange={handleSelectChange}
      className='gap-selection gender-selection'
    />
  );
}
