import { useEffect, useState } from "react";
import PieChart from "@components/charts/PieChart";
import { getCountriesInfo, getChurchMissionInfo } from "@db/users.js";
import { getColor, adjustColor, calculateTextColor } from "@utils/colors.js";
import { countryCodeToName } from "@utils/countryUtils.js";
import "@styles/campers.css";

export default function CampData() {
  const [countries, setCountries] = useState([]); // [[label, value, color], [label, value, color], [label, value, color]
  const [countryDisplay, setCountryDisplay] = useState([]);
  const [churchMission, setChurchMission] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await getCountriesInfo();
      const arrayCountries = [];
      countries.map((c) => {
        const name = countryCodeToName(c.name);
        const country = {
          name: c.name,
          total: c.total,
        };
        arrayCountries.push(country);
        return null; // Adding a return statement to avoid a warning
      });

      setCountryDisplay(arrayCountries);
      setData(arrayCountries, setCountries);
    };

    const fetchChurchMission = async () => {
      const churchMission = await getChurchMissionInfo();
      const arrayChuchMission = [];
      churchMission.map((c) => {
        const country = {
          name: c.name,
          total: c.total,
        };
        arrayChuchMission.push(country);
        return null; // Adding a return statement to avoid a warning
      });
      setChurchMission(arrayChuchMission);
      setData(arrayChuchMission, setChurchMission);
    };

    const setData = async (data, setData) => {
      const array = [];
      data.map((d, index) => {
        const color = getColor(index);
        const hoverColor = adjustColor(color, 20);
        const obj = {
          label: d.name,
          value: d.total,
          color: color,
          hover: hoverColor,
        };
        array.push(obj);
        return null; // Adding a return statement to avoid a warning
      });
      setData(array);
    };
    fetchCountries();
    fetchChurchMission();
  }, []);

  return (
    <div className='center-grid width-100'>
      <h2>Church & Missions</h2>
      <PieChart dataObject={churchMission} />
      <div className='box-flex-wrap'>
        {churchMission.map((c, index) => {
          console.log(getColor(index));
          console.log(calculateTextColor(getColor(index)));
          return (
            <div key={index} className='box-row flag-box'>
              <p className='flag-text text-oval' style={{backgroundColor: getColor(index), color: calculateTextColor(getColor(index)).hex} }>
                {c.label}: {c.value}
              </p>
            </div>
          );
        })}
      </div>
      <h2>Countries</h2>
      <PieChart dataObject={countries} />
      <div className='box-flex-wrap'>
        {countryDisplay.map((c, index) => {
          return (
            <div key={index} className='box-row flag-box'>
              <span className={`flag flag-${c.name.toLowerCase()}`}></span>
              <p className='flag-text'>
                {countryCodeToName(c.name)}: {c.total}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
