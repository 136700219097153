import supabase from "../config/supabase.js";
import { getRoomNamebyId } from "@db/rooms.js";
import { isSharingBed } from "@utils/getAge.js";

export const roomsUpdateSubscribe = (valueChange) => {
  supabase
    .channel("room-update")
    .on(
      "postgres_changes",
      { event: "UPDATE", schema: "public", table: "users" },
      (payload) => {
        valueChange({
          room_id: payload.new.room_id,
          id: payload.new.id,
          name: `${payload.new.first_name} ${payload.new.last_name} ${
            isSharingBed(payload.new.birthday) ? "🚼" : ""
          }`,
        });
      }
    )
    .subscribe();
};

export const teamsUpdateSubscribe = (valueChange) => {
  supabase
    .channel("team-update")
    .on(
      "postgres_changes",
      { event: "UPDATE", schema: "public", table: "users" },
      (payload) => {
        valueChange({
          team_id: payload.new.team_id ? payload.new.team_id : 0,
          id: payload.new.id,
          name: `${payload.new.first_name} ${payload.new.last_name}`,
          birthday: `${payload.new.birthday}`,
          gender: `${payload.new.gender}`,
        });
      }
    )
    .subscribe();
};

export const unsubscribeAllChannels = () => {
  const channels = supabase.getChannels();
  if (channels.length > 0) {
    supabase.removeAllChannels();
  }
};
