import { useState, useEffect } from "react";
import { SelectButton } from "primereact/selectbutton";
import { getRoomInfoUsers } from "@db/rooms.js";
import { getAdminSettingsBool } from "@db/admin.js";

export default function SelectAccomodation({ onSelectChange }) {
  const [accommodation, setAccommodation] = useState("");
  const [roomData, setRoomData] = useState("");
  const [fullyBooked, setFullyBooked] = useState(true);

  const accommodationOptions = [
    {
      name: fullyBooked ? "All rooms are fully booked 🛏️" : "Room 🛏️",
      value: "Room",
      disabled: fullyBooked,
    },
    { name: "Tent ⛺️ (Stay in)", value: "Tent" },
    {
      name: "Bring own sleeping bag (Stay in)",
      value: "Bring own sleeping/matress",
    },
    { name: "Stay out 🏠", value: "Stay out" },
  ];

  const handleSelectChange = (e) => {
    setAccommodation(e.value);
    onSelectChange(e.value); // Call the callback function with the selected value
  };

  useEffect(() => {
    const roomsData = async () => {
      const data = await getRoomInfoUsers();
      setRoomData(data);

      const disableBookBed = await getAdminSettingsBool(
        "DISABLE_BOOK_BED"
      );
      if (disableBookBed || data.fully_booked) setFullyBooked(true);
      else setFullyBooked(false);
    };
    roomsData();
  }, []);

  return (
    <div className='center white-text'>
      <p className='member-boolean-txt accomodation-info-list'>
        {" "}
        Accomodation{" "}
      </p>
      <p className='text-white '>
        Be aware that selecting a room option requires full payment for
        reservation confirmation. In cases of late payment or if rooms are no
        longer available, you will be moved to the 'Stay in' category, for which
        a personal tent or sleeping bag is necessary.
      </p>
      <p>Children 7 and below will share a bed with their parent/guardian. </p>
      <p>
        Please make your selection carefully, as it cannot be altered later.
      </p>
      <SelectButton
        value={accommodation}
        options={accommodationOptions}
        onChange={handleSelectChange}
        optionLabel='name'
        className='gap-selection center-selection'
        optionDisabled='disabled'
      />
    </div>
  );
}
