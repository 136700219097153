import React from "react";
import { Button } from "primereact/button";

export default function NotFoundPage() {
  return (
    <>
      <h1 className='text-h1'>404 ERROR</h1>
      <div className='box-column'>
        <h2 className='section-subtitle'>No page found</h2>
        <Button label='Go back' onClick={() => window.history.back()} />
      </div>
    </>
  );
}
