import { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

export default function SelectMissions({ onSelectChange }) {
  const [church, setChurch] = useState("");
  const [otherChurch, setOtherChurch] = useState("");

  const churchMissionSelect = [
    { label: "Copenhagen Bethel Missionary Baptist Church 🇩🇰", value: "CBMBC" },
    { label: "Malmö Bethel Missionary Baptist Church 🇸🇪", value: "MBMBC" },
    { label: "Barcelona Bethel Missionary Baptist Mission 🇪🇸", value: "BBMBM" },
    {
      label: "Netherlands Bethel Missionary Baptist Mission 🇳🇱",
      value: "NBMBM",
    },
    { label: "Other", value: "Other" },
    { label: "None", value: "None" },
  ];

  const handleSelectChange = (e) => {
    setChurch(e.value);
    if (e.value == null || e.value === "Other" && !otherChurch) {
      onSelectChange(null);
    } else if (e.value === "Other") {
      // onSelectChange(otherChurch);
    } else {
      onSelectChange(e.value);
    }
  };

  const handleOtherSelectChange = (e) => {
    setOtherChurch(e.target.value);
    onSelectChange(e.target.value)
  };

  const [displayOtherChurchTextfield, setDisplayOtherChurchTextfield] =
    useState({ display: "none" });

  useEffect(() => {
    if (church === "Other") {
      setDisplayOtherChurchTextfield({ display: "block" });
    } else {
      setDisplayOtherChurchTextfield({ display: "none" });
    }
  }, [church]);

  return (
    <>
      <Dropdown
        value={church}
        options={churchMissionSelect}
        onChange={handleSelectChange}
        placeholder='Select Church/Mission'
      />
      <InputText
        placeholder='Please specify another Church/Mission'
        value={otherChurch}
        style={displayOtherChurchTextfield}
        onChange={handleOtherSelectChange}
      />
    </>
  );
}
