import { Outlet, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DeveloperMode } from "@/config/DeveloperMode";

const TestRoute = () => {
  const [auth, setAuth] = useState(null);
  useEffect(() => {
    checkIfInTestMode();
  }, []);

  const checkIfInTestMode = () => {
    if (DeveloperMode()) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  };

  return auth === null ? null : auth ? <Outlet /> : <Navigate to='/' />;
};

export default TestRoute;
