import React, {useState} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { setTeamName } from '@db/teams.js';

export default function EditInputText({
  dialogIsVisible,
  setDialogIsVisible,
  value,
  setValue,
  id,
  reload
}) {

  const [valueInput,setValueInput] = useState('')
  

  const handleChange = (e) => {
    setValueInput(e.target.value)
    setValue(e.target.value)
  }

  const handleSaveChange = async () => {
    await setTeamName(id,valueInput)
    setDialogIsVisible(false)
    reload && window.location.reload()
  }

  return (
    <Dialog
      visible={dialogIsVisible}
      modal
      onHide={() => setDialogIsVisible(false)}
      className='dark-bg'
      showHeader={false}
    >
      <div className='box-column box-responsive'>
        <InputText
          value={value}
          onChange={handleChange}
        />
        <div className='box-row'>
          <Button
            label='Save'
            icon='pi pi-check'
            onClick={() => handleSaveChange()}
            autoFocus
          />
          <Button
            label='Cancel'
            icon='pi pi-times'
            onClick={() => setDialogIsVisible(false)}
            className='btn-delete'
          />
        </div>
      </div>
    </Dialog>
  );
}
