import React from "react";
import "@styles/section.css";

export default function Section({
  children,
  title,
  subtitle,
  color,
  background,
}) {
  if (!background) background = "none";

  // Inline styles to dynamically set the color of text elements
  const titleStyle = { color: color };
  const subtitleStyle = { color: color };

  return (
    <section className='section' style={{ backgroundColor: background }}>
      <h2 className='section-text section-h1 text-title-responsive' style={titleStyle}>{title}</h2>
      <h3 className='section-subtitle text-subtitle-responsive' style={subtitleStyle}>{subtitle}</h3>
      <div className='section-div'>{children}</div>
    </section>
  );
}
