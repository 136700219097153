import { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";

export default function SettingSwitch({ setting, onUpdate }) {
  const [checked, setChecked] = useState(setting.bool);

  const onSwitchChange = async (e) => {
    await onUpdate(setting.name, e.value);
    setChecked(e.value);
  };
  return (
      <div className='box-row'>
        <InputSwitch checked={checked} onChange={onSwitchChange} />
        <p className="box-center-grid text-no-space">{setting.description}</p>
      </div>
  );
}
