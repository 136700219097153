import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  getRoomsInfoDataUser,
  getRoomNamebyId,
  getRooms,
  getRoomInfoUsers,
} from "@db/rooms.js";
import EditButton from "@components/buttons/EditButton";
import SelectRooms from "../form/SelectRooms.js";
import { roomsUpdateSubscribe } from "@db/subscribe.js";
import { onGlobalFilterChange } from "@utils/table.js";
import { Accordion } from "primereact/accordion";
import { AccordionTab } from "primereact/accordion";
import RoomsDisplay from "@components/display/RoomsDisplay.js";
import { isSharingBed } from "@utils/getAge.js";

export default function RoomsAdmin() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [updateData, setUpdateData] = useState();
  const [displayData, setDisplayData] = useState([]);
  const [roomInfo, setRoomInfo] = useState({});

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  roomsUpdateSubscribe(setUpdateData);

  useEffect(() => {
    const fetchRoomDataName = async (id) => {
      return await getRoomNamebyId(id);
    };

    const updateTableData = async () => {
      console.log(updateData);
      const roomName = updateData.room_id
        ? await fetchRoomDataName(updateData.room_id)
        : "Unassigned";
      setTableData((prevTableData) => {
        return prevTableData.map((u) => {
          if (u.id === updateData.id) {
            return { ...u, room: roomName.name ? roomName.name : "Unassigned" };
          }
          return u;
        });
      });
    };
    if (updateData) {
      updateTableData();
    }
  }, [updateData]);

  useEffect(() => {
    const fetchRoomData = async () => {
      setRoomInfo(await getRoomInfoUsers());
      const users = await getRoomsInfoDataUser();
      const roomGroup = await getRooms(users);
      setDisplayData(roomGroup);

      const arrayUser = [];
      users.map((u) => {
        if (u.accommodation !== "Room") return;
        const user = {
          name: `${u.first_name} ${u.last_name} ${
            isSharingBed(u.birthday) ? "🚼" : ""
          }`,
          room: u.room_id ? u.room_id.name : "Unassigned",
          id: u.id,
          room_id: u.room_id ? u.room_id.id : null,
          room_capacity: u.room_id ? u.room_id.capacity : null,
        };
        arrayUser.push(user);
      });
      setTableData(arrayUser);
    };

    fetchRoomData();
  }, []);

  const renderHeader = () => {
    return (
      <>
        <div className='flex justify-content-end'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={globalFilterValue}
              onChange={(e) =>
                onGlobalFilterChange(
                  e,
                  filters,
                  setFilters,
                  setGlobalFilterValue
                )
              }
              placeholder='Search Camper'
            />
          </span>
        </div>
      </>
    );
  };

  const buttoEditTemplate = (rowData) => {
    return (
      <EditButton uuid={rowData.id} updateData={{ room_id: null }} setNull>
        <SelectRooms rowData={rowData} />
      </EditButton>
    );
  };

  const columns = [
    { field: "name", header: "Name", sortable: true },
    { field: "room", header: "Room", sortable: true },
    { field: "room_id", header: "RoomId", sortable: true, hidden: true },
    { field: "edit", header: "Edit", sortable: false, body: buttoEditTemplate },
    { field: "id", header: "id", sortable: false, hidden: true },
  ];

  return (
    <>
      <div className='center-grid'>
        <h3>Shows only registered campers</h3>
        <div className='box-flex-wrap gap-20'>
          <p className='text-center tag-green'>
            Available <br />
            {roomInfo.available}
          </p>
          <p className='text-center tag-orange'>
            Reserved <br /> {roomInfo.reserved}
          </p>
          <p className='text-center tag-blue'>
            Total beds <br />
            {roomInfo.totalBeds}
          </p>
        </div>
      </div>
      <Accordion>
        <AccordionTab header='Rooms'>
          <RoomsDisplay displayData={displayData} updateData={updateData} />
        </AccordionTab>
        <AccordionTab header='Rooms Table'>
          <DataTable
            header={renderHeader()}
            removableSort
            value={tableData}
            emptyMessage='No campers found.'
            sortField='created_at'
            sortOrder={-1}
            resizableColumns
            stripedRows
            showGridlines
            scrollable
            responsiveLayout='scroll'
            scrollHeight='700px'
            selectionMode='single'
            globalFilterFields={["name"]}
            filters={filters}
          >
            {columns.map((col, i) => (
              <Column
                key={i}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                hidden={col.hidden}
                body={col.body}
              />
            ))}
          </DataTable>
        </AccordionTab>
      </Accordion>
    </>
  );
}
