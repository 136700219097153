import supabase from "@config/supabase.js";
import { getTotalRooms } from "@utils/globalVariables.js";
import { isSharingBed } from "@utils/getAge.js";

const totalRoom = getTotalRooms();

export const setRoomsForUser = async (userId, roomId) => {
  const { data, error } = await supabase
    .from("users")
    .update({ room_id: roomId })
    .eq("id", userId);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getRoomInfoUsers = async () => {
  const { data, error } = await supabase
    .from("users")
    .select("first_name")
    .in("pricing_id", [0, 1, 2, 7, 10, 11, 12, 13])
    .eq("accommodation", "Room")
    .eq("is_registered", true);
  if (error) {
    console.log("An error occured: getRoomInfoUsers");
    console.log(error);
    return;
  }

  const totalrooms = totalRoom;

  const roomData = {
    available: totalrooms - data.length,
    fully_booked: totalrooms <= data.length,
    reserved: data.length,
    totalBeds: totalrooms,
  };
  return roomData;
};

export const getRoomsInfoDataUser = async () => {
  const { data, error } = await supabase
    .from("users")
    .select(
      "id, first_name, last_name, birthday, accommodation, gender, room_id (id,name,capacity)"
    )
    .eq("is_registered", true);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getRoomInfo = async () => {
  const { data, error } = await supabase
    .from("rooms")
    .select("*")
    .order("id", { ascending: true })
    .lt("id", 21);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getRoomNamebyId = async (id) => {
  const { data, error } = await supabase
    .from("rooms")
    .select("name")
    .eq("id", id)
    .limit(1)
    .single();

  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getAllCounselorsArray = async () => {
  const { data, error } = await supabase.from("rooms").select("counselor_id");
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data.map((item) => item.counselor_id);
};

export const getRooms = async (people) => {
  const { data, error } = await supabase
    .from("rooms")
    .select("id, name, capacity, counselor_id (id, first_name, last_name)")
    .order("id", { ascending: true });
  if (error) {
    console.log("An error occurred: ");
    console.log(error);
    return [];
  }

  // Assuming data is an array of rooms fetched from the database
  const formattedRoomsData = data.map((room) => {
    let leaderName = null;

    // Check if counselor_id exists and if both first_name and last_name are not null
    if (
      room.counselor_id &&
      room.counselor_id.first_name !== null &&
      room.counselor_id.last_name !== null
    ) {
      leaderName =
        room.counselor_id.first_name + " " + room.counselor_id.last_name;
    }

    return {
      id: room.id,
      title: room.name,
      subTitle: room.capacity ? `Capacity: ${room.capacity}` : null,
      leader: {
        id: room.counselor_id,
        name: leaderName,
      },
      list: [], // Initialize an empty array for people
    };
  });

  // Add an "unassigned" object at the beginning of the array
  formattedRoomsData.unshift({
    id: 0,
    title: "Unassigned",
    subTitle: null,
    leader: {
      id: null,
      name: null,
    },
    list: [],
  });

  // Iterate through people and insert them into respective rooms
  // Iterate through each person and assign them to rooms or other accommodations
  people.forEach((person) => {
    let roomIndex;
    let roomId;

    if (person.accommodation !== "Room") {
      // Handle special accommodation logic
      roomId = getRoomIdFromAccomodation(person.accommodation, person.gender);
    } else {
      // Default to assigned room or 'Unassigned'
      roomId = person.room_id ? person.room_id.id : 0;
    }

    roomIndex = formattedRoomsData.findIndex((room) => room.id === roomId);

    // Prepare the name, appending a star if this person is the leader
    const isLeader = person.id === formattedRoomsData[roomIndex].leader.id?.id;
    const nameSuffix = isLeader
      ? " ⭐️"
      : isSharingBed(person.birthday)
      ? " 🚼"
      : "";

    // Add each person to the appropriate room list
    formattedRoomsData[roomIndex].list.push({
      id: person.id,
      name: `${person.first_name} ${person.last_name}${nameSuffix}`,
    });
  });

  // Apply the sorting logic to all rooms
  formattedRoomsData.forEach((room) => {
    room.list.sort((a, b) => {
      if (a.name.includes("⭐️")) return -1; // Leader comes first
      if (b.name.includes("⭐️")) return 1; // Ensure leader stays first if compared against
      return a.name.localeCompare(b.name); // Alphabetically sort by name for the rest
    });
  });

  return formattedRoomsData;
};

const getRoomIdFromAccomodation = (accommodation, gender) => {
  switch (accommodation) {
    case "Bring own sleeping/matress":
    case "Stay in":
      return gender === "Male" ? 21 : 22;
    case "Tent":
      return 23;
    case "Stay out":
      return 24;
    default:
      return 0;
  }
};
