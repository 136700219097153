import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState, useEffect } from "react";
import { getAllUsers, countUsersByRegistrationStatus } from "@db/users.js";
import { formatLocalDateTime } from "@utils/dateTime.js";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import PieChart from "@components/charts/PieChart";
import { Accordion } from "primereact/accordion";
import { AccordionTab } from "primereact/accordion";
import { countryCodeToName } from "@utils/countryUtils.js";
import ExportExcelButton from "@components/buttons/ExportExcelButton.js";
import { getAccessLevelOfCurrentUser } from "@db/admin.js";
import { getAllAdminSettings } from "@db/admin.js";

export default function ListAdmin() {
  const [users, setUsers] = useState([]);
  const [frozenName, setFrozenName] = useState(true);
  const [labelFrezze, setLabelFrezze] = useState("Unfrezze name");
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [dataChart, setDataChart] = useState([]); // [[label, value, color], [label, value, color], [label, value, color]]
  const [amountRegistered, setAmountRegistered] = useState({});
  const [accessLevel, setAccessLevel] = useState(null);
  const [showTeams, setShowTeams] = useState(false);
  const [showRooms, setShowRooms] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getAllUsers();
      const arrayUser = [];
      const accessLevel = await getAccessLevelOfCurrentUser();
      const settings = await getAllAdminSettings();
      const showTeams = settings.showTeams;
      const showRooms = settings.showRooms;

      let _showTeams = showTeams;
      let _showRooms = showRooms;

      if(accessLevel >= 3 || showRooms) _showRooms = true;
      if(accessLevel >= 3 || showTeams) _showTeams = true;
        
      users.map((u) => {
        const us = {
          name: u.first_name + " " + u.last_name,
          birthday: u.birthday,
          country: countryCodeToName(u.country),
          is_registered: u.is_registered ? "Yes" : "No",
          registered_at: u.registered_at
            ? formatLocalDateTime(u.registered_at)
            : "",
          accommodation: u.accommodation,
          gender: u.gender,
          email: u.email,
          church_mission: u.church_mission,
          size: u.size,
          team:
            _showTeams && u.team_id ? u.team_id.name : "",
          arrival_transportation: u.arrival_transportation,
          departure_transportation: u.departure_transportation,
          phone: u.phone,
          room:
             u.accommodation != "Room"
              ? u.accommodation
              : _showRooms && u.room_id
              ? u.room_id.name
              : "Unassigned",
          parent_id: u.parent
            ? u.parent.first_name + " " + u.parent.last_name
            : "",
          pricing_name: u.pricing.name,
          pricing_price: u.pricing.price,
        };
        arrayUser.push(us);
        return null; // Adding a return statement to avoid a warning
      });

      //Sort user in alphabetical order and registered_at in descending order
      arrayUser.sort((a, b) => {
        if (a.registered_at < b.registered_at) return 1;
        if (a.registered_at > b.registered_at) return -1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      setUsers(arrayUser);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    const fetchUserStatus = async () => {
      const notRegistered = await countUsersByRegistrationStatus(false);
      const registered = await countUsersByRegistrationStatus(true);
      setAmountRegistered({ true: registered, false: notRegistered });
      setDataChart([
        {
          label: "Registered",
          value: registered,
          color: "#24C86A",
          hover: "#3AEA85",
        },
        {
          label: "Not registered",
          value: notRegistered,
          color: "#F51342",
          hover: "#FF446B",
        },
      ]);
    };

    fetchUsers();
    fetchUserStatus();
  }, []);

  const columns = [
    { field: "name", header: "Name", sortable: true, frozen: true },
    { field: "birthday", header: "Birthday", sortable: true },
    { field: "country", header: "Country", sortable: true },
    { field: "accommodation", header: "Accommodation", sortable: true },
    { field: "is_registered", header: "Registered", sortable: true },
    { field: "registered_at", header: "Registered at", sortable: true },
    { field: "gender", header: "Gender", sortable: true },
    { field: "email", header: "Email", sortable: true },
    { field: "church_mission", header: "Church", sortable: true },
    { field: "size", header: "Size", sortable: true },
    { field: "team", header: "Team", sortable: true },
    { field: "arrival_transportation", header: "Arrival", sortable: true },
    { field: "departure_transportation", header: "Departure", sortable: true },
    { field: "phone", header: "Phone", sortable: true },
    { field: "room", header: "Room", sortable: true },
    { field: "parent_id", header: "Parent", sortable: true },
    { field: "pricing_name", header: "Price name", sortable: true },
    { field: "pricing_price", header: "Price", sortable: true },
  ];

  const frezzeName = () => {
    if (frozenName) setLabelFrezze("Frezze name");
    else setLabelFrezze("Unfrezze name");
    setFrozenName(!frozenName);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder='Search Camper'
        />
      </span>
      <Button label={labelFrezze} onClick={frezzeName} />
      <ExportExcelButton value={users} fileName='camper_list' />
    </>
  );

  return (
    <>
      <div className='center-grid width-100'>
        <PieChart dataObject={dataChart} />
        <br />
        <div className='box-flex-wrap gap-20'>
          <p className='text-center tag-green'>
            Registered: {amountRegistered.true}
          </p>
          <p className='text-center tag-red'>
            Not registered: {amountRegistered.false}
          </p>
          <p className='text-center tag-blue'>
            Total: {amountRegistered.true + amountRegistered.false}
          </p>
        </div>
      </div>
      <Accordion>
        <AccordionTab header='Camper list'>
          <DataTable
            value={users}
            size='small'
            responsiveLayout='scroll'
            scrollable
            selectionMode='single'
            showGridlines
            stripedRows
            filters={filters}
            globalFilterFields={["name"]}
            header={header}
            scrollHeight='90vh'
            resizableColumns
            className='admin-list-table'
            loading={loading}
          >
            {columns.map((col, i) => (
              <Column
                key={i}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                frozen={frozenName ? col.frozen : false}
              />
            ))}
          </DataTable>
        </AccordionTab>
      </Accordion>
    </>
  );
}
