 
 export function uuidv4Generator() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  export const shuffleLetters = (word) => {
    // Shift each letter to the next letter in the alphabet
    let shiftedWord = "";
    for (let i = 0; i < word.length; i++) {
      let currentCharCode = word.charCodeAt(i);
      let nextCharCode = currentCharCode + Math.floor(Math.random() * 10) + 1;
      let nextChar = String.fromCharCode(nextCharCode);
      shiftedWord += nextChar;
    }

    // Scramble the shifted word
    let scrambledWord = "";
    while (shiftedWord.length > 0) {
      let randomIndex = Math.floor(Math.random() * shiftedWord.length);
      scrambledWord += shiftedWord[randomIndex];
      shiftedWord =
        shiftedWord.slice(0, randomIndex) + shiftedWord.slice(randomIndex + 1);
    }
    return scrambledWord;
  };
