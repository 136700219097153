import React from "react";
import { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

//dataObject is an array of objects with the following structure:
// [
//   { label: "Label 1", value: 300, color: "#FF6384", hover: "#FF6384" },
//   { label: "Label 2", value: 50, color: "#36A2EB", hover: "#36A2EB" },
//   { label: "Label 3", value: 100, color: "#FFCE56", hover: "#FFCE56" },
// ]
export default function PieChart({ dataObject }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);

    // Extract labels, data values, and colors from dataObject
    const labels = dataObject.map((obj) => obj.label);
    const dataValues = dataObject.map((obj) => obj.value);
    const backgroundColors = dataObject.map((obj) => obj.color);
    const hoverBackgroundColors = dataObject.map((obj) => obj.hover); // Assuming you have hover color variables
    const data = {
      labels,
      datasets: [
        {
          data: dataValues,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: hoverBackgroundColors,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [dataObject]); // Add dataObject as a dependency to re-run the effect when it changes

  return (
    <Chart
      type='pie'
      data={chartData}
      options={chartOptions}
      className='w-full md:w-30rem'
    />
  );
}
