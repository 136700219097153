import { Outlet, Navigate } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { authValid } from "./auth";
import supabase from "../config/supabase";
import { DeveloperMode } from "@/config/DeveloperMode";
import { LoadingData } from "@components/status/Loading.js";
import { checkIfUserIsAdmin } from "@db/admin.js";

const PrivateRoutes = () => {
  const [auth, setAuth] = useState(null);
  const [uuid, setUuid] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const checkIfUserLoggedIn = async () => {
      const _uuid = await authValid();
      setCount(count + 1);
      if (_uuid) {
        setUuid(_uuid.user.id);
      }
    };
    checkIfUserLoggedIn();
  }, []);

  useEffect(() => {
    if (uuid) {
      fetchAdminChecker();
    }
  }, [uuid]);

  const fetchAdminChecker = async () => {
    setAuth(await checkIfUserIsAdmin(uuid));
  };

  const returnFunction = () => {
    // if (DeveloperMode()) {
    //   return <Outlet />;
    // } else 
    if (auth === null) {
      return <LoadingData />;
    } else if (auth === false) {
      return <Navigate to='/' />;
    } else {
      return <Outlet />;
    }
  };

  return <>{returnFunction()}</>;
};

export default PrivateRoutes;
