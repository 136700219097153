import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function ProfileSettings() {
  const nav = useNavigate();
  useEffect(() => {
    localStorage.setItem("activeTab", 5);
  }, []);

  return (
    <Button
      className="prime"
      label='Reset password'
      onClick={() => nav("/password-reset")}
    ></Button>
  );
}
