import supabase from "../config/supabase.js";
import { getCurrentUserFromSupabase } from "./currentUser.js";

export const getPricingFromCurrentUser = async () => {
  const user = await getCurrentUserFromSupabase();
  const { data, error } = await supabase
    .from("users")
    .select(`pricing (id, name, price)`)
    .eq(`id`, user.id)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured in getPricingFromCurrentUser:");
    console.log(error);
    return;
  }
  return data.pricing;
};

export const getPricingUserParams = async (uuid) => {
  const { data, error } = await supabase
    .from("users")
    .select(`accommodation, pricing (id, name, price)`)
    .eq(`id`, uuid)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured in getPricingUserParams:");
    console.log(error);
    return;
  }
  return data;
};

export const setPricingofUser = async (uuid, pricingId) => {
  const { data, error } = await supabase
    .from("users")
    .update({ pricing_id: pricingId })
    .eq("id", uuid);
  if (error) {
    console.log("An error occured in setPricingofUser:");
    console.log(error);
    return;
  }
  return data;
};

export const getPricingIdByName = async (name) => {
  const { data, error } = await supabase
    .from("pricing")
    .select("id")
    .eq("name", name)
    .limit(1)
    .single();

  if (error && error.details === "The result contains 0 rows") return null;
  else if (error) {
    console.log("An error occured in getPricingIdByName:");
    console.log(error);
    return;
  }

  return data.id;
};

export const getPricingInfoById = async (id) => {
  const { data, error } = await supabase
    .from("pricing")
    .select("name, price")
    .eq("id", id)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured in getPricingNameById:");
    console.log(error);
    return;
  }
  return data;
}

export const getAllPricingInfo = async () => {
  const { data, error } = await supabase
    .from("pricing")
    .select("id, name, price")
    .lte("id", 9)
  if (error) {
    console.log("An error occured in getAllPricingInfo:");
    console.log(error);
    return;
  }
  return data;
}