import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getTeamsList } from "@db/teams.js";
import EditInputText from "@components/edit/EditInputText.js";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import SelectPerson from "../form/SelectPerson.js";
import { Button } from "primereact/button";
import { setTeamLeader, setTeamColor } from "@db/teams.js";

export default function TeamsConfig() {
  const [tableData, setTableData] = useState([]);
  const [selectedCell, setSelectedCell] = useState(null);
  const [dialogIsVisible, setDialogIsVisible] = useState(false);
  const [inputTextValue, setInputTextValue] = useState(null);
  const [id, setId] = useState(null);
  const [dialogLeaderIsVisible, setDialogLeaderIsVisible] = useState(false);
  const [selectedLeader, setSelectedLeader] = useState("");
  const [dialogColorPickerIsVisible, setDialogColorPickerIsVisible] =
    useState(false);
  const [currentTeam, setCurrentTeam] = useState({
    name: null,
    id: null,
    color_code: null,
  });
  const [colorPickerSelected, setColorPickerSelected] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = await getTeamsList();
      const arrayData = data.map((u) => ({
        id: u.id,
        name: u.name,
        color_code: u.color_code || "#FFFFFF",
        leader: u.leader_id
          ? u.leader_id.first_name + " " + u.leader_id.last_name
          : null,
      }));
      setTableData(arrayData);
    };

    fetchData();
  }, []);

  const handleColorChange = (event, rowData, rowIndex) => {
    const { value } = event.target;
    const updatedTableData = [...tableData];
    if (updatedTableData[rowIndex]) {
      updatedTableData[rowIndex].color_code = value;
      setTableData(updatedTableData);
    }
  };

  const colorPicker = (rowData, rowIndex) => {
    return (
      <>
        <div className='box-center-grid'>
          <div
            className='team-color-picker column-box'
            style={{ backgroundColor: rowData.color_code }}
          ></div>
        </div>
        <p></p>
      </>
    );
  };

  const onClickCell = (e) => {
    setInputTextValue("");
    setId(null);
    console.log({
      name: e.rowData.name,
      id: e.rowData.id,
      color: e.rowData.color_code,
    });
    setCurrentTeam({
      name: e.rowData.name,
      id: e.rowData.id,
      color: e.rowData.color_code,
    });
    setColorPickerSelected(e.rowData.color_code);
    if (e.field === "name") {
      setInputTextValue(e.value);
      setId(e.rowData.id);
      setDialogIsVisible(true);
    } else if (e.field === "color") {
      setDialogColorPickerIsVisible(true);
      setId(e.rowData.id);
    } else if (e.field === "leader") {
      setDialogLeaderIsVisible(true);
      setId(e.rowData.id);
    }
  };

  const leaderHeader = () => {
    return (
      <p className='text-center text-white'>
        Select a leader for
        <br />
        {currentTeam.name}
      </p>
    );
  };

  const handleSaveLeaderChange = async () => {
    await setTeamLeader(currentTeam.id, selectedLeader);
    setDialogLeaderIsVisible(false);
    window.location.reload();
  };

  const handleSaveColorChange = async () => {
    await setTeamColor(currentTeam.id, colorPickerSelected);
    setDialogColorPickerIsVisible(false);
    window.location.reload();
  };

  return (
    <>
      <DataTable
        value={tableData}
        showGridlines
        stripedRows
        removableSort
        selectionMode='single'
        size={"small"}
        cellSelection
        selection={selectedCell}
        onSelectionChange={(e) => {
          onClickCell(e.value);
          setSelectedCell(e.value);
        }}
      >
        <Column field='name' header='Name'></Column>
        <Column
          field='color'
          header='Color'
          body={(rowData, rowIndex) => colorPicker(rowData, rowIndex)}
        ></Column>
        <Column field='leader' header='Leader'></Column>
      </DataTable>
      <EditInputText
        dialogIsVisible={dialogIsVisible}
        setDialogIsVisible={setDialogIsVisible}
        value={inputTextValue}
        setValue={setInputTextValue}
        id={id}
        reload
      />
      <Dialog
        className='dark-bg'
        visible={dialogColorPickerIsVisible}
        onHide={() => setDialogColorPickerIsVisible(false)}
        showHeader={false}
      >
        <div className='box-column box-responsive'>
          <p className='text-white text-center'>
            Set color for
            <br />
            {currentTeam.name}
          </p>
          <input
            type='color'
            className='box-center-grid team-color-picker'
            value={colorPickerSelected}
            onChange={(e) => setColorPickerSelected(e.target.value)}
            readOnly
          />
          <p className='text-white text-center'>
            Click on the box to change a color
          </p>
          <div className='box-row'>
            <Button
              label='Save'
              icon='pi pi-check'
              onClick={handleSaveColorChange}
              autoFocus
            />
            <Button
              label='Cancel'
              icon='pi pi-times'
              onClick={() => setDialogColorPickerIsVisible(false)}
              className='btn-delete'
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        className='dark-bg'
        visible={dialogLeaderIsVisible}
        onHide={() => setDialogLeaderIsVisible(false)}
        showHeader={false}
      >
        <div className='box-column box-responsive'>
          <SelectPerson
            team
            teamId={id}
            onSelectChange={setSelectedLeader}
            header={leaderHeader}
          />

          <div className='box-row'>
            <Button
              label='Save'
              icon='pi pi-check'
              onClick={handleSaveLeaderChange}
              autoFocus
            />
            <Button
              label='Cancel'
              icon='pi pi-times'
              onClick={() => setDialogLeaderIsVisible(false)}
              className='btn-delete'
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}
