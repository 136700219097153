import React from "react";
import { motion } from "framer-motion";

export function SlideUp({ children, delay }) {
  if (delay === undefined) delay = 0;
  else delay = delay * 0.075;

  // Define the animation variants
  const variants = {
    hidden: { opacity: 0, y: 90 }, // Start from a lower position
    visible: {
      opacity: 1,
      y: 0, // Animate to the original position
      transition: { duration: 1.5, ease: "easeOut", delay: delay }, // Customize the transition (optional)
    },
  };

  return (
    <motion.div
      initial='hidden'
      whileInView='visible'
      variants={variants} // Apply the animation variants
      viewport={{ once: true }} // Optional: ensure animation plays only once when element comes into view
    >
      {children}
    </motion.div>
  );
}


