import { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";

export default function SelectSize({ onSelectChange, age, edit }) {
  const [shirtSize, setShirtSize] = useState("");
  const [sizeOptions, setSizeOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [babySizes, setBabySizes] = useState(null);
  const [label, setLabel] = useState(
    "Enter a valid birthday prior to choosing a size"
  );
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);

  const babySize = [{ label: "Baby size", value: "Baby size" }];

  const adultSize = [
    { label: "X-Large", value: "X-Large" },
    { label: "XX-Large", value: "XX-Large" },
    { label: "XXX-Large", value: "XXX-Large" },
  ];

  const commonSize = [
    { label: "X-Small", value: "X-Small" },
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
  ];

  useEffect(() => {
    if (edit) {
      setIsDisabled(false);
      setLabel("Select T-shirt size");
      return setSizeOptions(
        age < 7
          ? babySize.concat(commonSize)
          : commonSize.concat(adultSize)
      );
    }
    if (!age) return setIsDisabled(true);
    else setIsDisabled(false);
    setLabel("Select T-shirt size");

    let newSizeOption = []; // Initialize newSizeOption

    // If age is less than 7, add babySize first, then commonSize
    if (age < 7) {
      newSizeOption = newSizeOption.concat(babySize, commonSize);
    } else {
      newSizeOption = newSizeOption.concat(commonSize, adultSize); // Otherwise, add commonSize first, then adultSize
    }
    setSizeOptions(newSizeOption); // Set sizeOptions
  }, [age]);

  const handleSelectChange = (e) => {
    if (e.value == null || (e.value === "Baby size" && !babySizes)) {
      onSelectChange(null);
    } else {
      onSelectChange(e.value);
    }
    setShirtSize(e.value);
  };

  const handleOtherSelectChange = (e) => {
    setBabySizes(e.value);
    console.log(e.value > 152);
    if (e.value === null) return onSelectChange(null);
    else if (e.value > 152) {
      onSelectChange(null);
      setError(true);
    } else {
      onSelectChange(e.value + " (Baby size)")
      setError(false);
    }
  };

  useEffect(() => {
    if (shirtSize === "Baby size") {
      onSelectChange(babySizes);
    }
  }, []);

  return (
    <>
      <Dropdown
        value={shirtSize}
        options={sizeOptions}
        onChange={handleSelectChange}
        placeholder={label}
        disabled={isDisabled}
      />
      {shirtSize === "Baby size" && (
        <>
          <InputNumber
            placeholder='Type Baby size (EU)'
            value={babySizes}
            onChange={handleOtherSelectChange}
            useGrouping={false}
          />
          {error && (
            <p className='text-red text-no-space'>
              Input too high, please enter below 153 or pick another size
            </p>
          )}
          <p className='navigate-text-left' onClick={() => setVisible(true)}>
            See baby sizes options (EU)
          </p>
        </>
      )}
      <Dialog
        header='Baby size chart (EU)'
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "50vw" }}
      >
        <div className='text-center'>
          <p>Size 50: 0-1 month</p>
          <p>Size 56: 1-2 months</p>
          <p>Size 62: 2-4 months</p>
          <p>Size 68: 4-6 months</p>
          <p>Size 74: 6-9 months</p>
          <p>Size 80: 9-12 months</p>
          <p>Size 86: 12-18 months</p>
          <p>Size 92: 18-24 months</p>
          <p>Size 98: 2-3 years</p>
          <p>Size 104: 3-4 years</p>
          <p>Size 110: 4-5 years</p>
          <p>Size 116: 5-6 years</p>
          <p>Size 122: 6-7 years</p>
          <p>Size 128: 7-8 years</p>
          <p>Size 134: 8-9 years</p>
          <p>Size 140: 9-10 years</p>
          <p>Size 146: 10-11 years</p>
          <p>Size 152: 11-12 years</p>
          {/* <p>Size 158: 12-13 years</p>
        <p>Size 164: 13-14 years</p>
        <p>Size 170: 14-15 years</p>
      <p>Size 176: 15-16 years</p> */}
        </div>
      </Dialog>
    </>
  );
}
