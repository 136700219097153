import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { onGlobalFilterChange } from "@utils/table.js";
import { Accordion } from "primereact/accordion";
import { AccordionTab } from "primereact/accordion";
import {
  getUsersTeams,
  getTeamNamebyId,
  getTeamsAndPeople,
} from "@db/teams.js";
import EditButton from "@components/buttons/EditButton";
import SelectTeams from "@components/form/SelectTeams.js";
import { teamsUpdateSubscribe } from "@db/subscribe.js";
import TeamsDisplay from "@components/display/TeamsDisplay.js";
import TeamsConfig from "@components/admin/TeamsConfig.js";
import "@styles/teams.css";

export default function TeamsAdmin() {
  const [tableData, setTableData] = useState([]);
  const [updateData, setUpdateData] = useState();
  const [displayData, setDisplayData] = useState([]);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  teamsUpdateSubscribe(setUpdateData);

  useEffect(() => {
    const fetchData = async () => {
      const users = await getUsersTeams();
      const teamAndUsers = await getTeamsAndPeople(users);
      setDisplayData(teamAndUsers);
      let arrayData = [];
      users.map((u) => {
        const user = {
          id: u.id,
          name: u.first_name + " " + u.last_name,
          team: u.team_id ? u.team_id.name : "Unassigned",
          team_id: u.team_id,
          leader:
            u.team_id && u.team_id.leader_id
              ? u.team_id.leader_id.first_name +
                " " +
                u.team_id.leader_id.last_name
              : null,
        };
        arrayData.push(user);
      });

      setTableData(arrayData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchTeamName = async (id) => {
      return await getTeamNamebyId(id);
    };

    const updateTableData = async () => {
      const teamName = updateData.team_id
        ? await fetchTeamName(updateData.team_id)
        : "Unassigned";
      setTableData((prevTableData) => {
        return prevTableData.map((u) => {
          if (u.id === updateData.id) {
            return { ...u, team: teamName.name ? teamName.name : "Unassigned" };
          }
          return u;
        });
      });
    };
    if (updateData) {
      updateTableData();
    }
  }, [updateData]);

  const buttoEditTemplate = (rowData) => {
    return (
      <EditButton uuid={rowData.id} updateData={{ team_id: null }} setNull>
        <SelectTeams rowData={rowData} />
      </EditButton>
    );
  };

  const columns = [
    { field: "name", header: "Name", sortable: true },
    { field: "team", header: "Team", sortable: true },
    { field: "team_id", header: "RoomId", sortable: true, hidden: true },
    { field: "edit", header: "Edit", sortable: false, body: buttoEditTemplate },
    { field: "id", header: "id", sortable: false, hidden: true },
  ];

  const renderHeader = () => {
    return (
      <>
        <div className='flex justify-content-end'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={globalFilterValue}
              onChange={(e) =>
                onGlobalFilterChange(
                  e,
                  filters,
                  setFilters,
                  setGlobalFilterValue
                )
              }
              placeholder='Search Camper'
            />
          </span>
        </div>
      </>
    );
  };

  return (
    <>
          <div className='center-grid'>
        <h3>Shows only registered campers</h3>
      </div>
      <Accordion>
        <AccordionTab header='Teams'>
          <TeamsDisplay displayData={displayData} updateData={updateData} />
        </AccordionTab>
        <AccordionTab header='Teams Table'>
          <DataTable
            header={renderHeader()}
            removableSort
            value={tableData}
            emptyMessage='No campers found.'
            // sortField='created_at'
            // sortOrder={-1}
            resizableColumns
            stripedRows
            showGridlines
            scrollable
            responsiveLayout='scroll'
            scrollHeight='700px'
            selectionMode='single'
            globalFilterFields={["name"]}
            filters={filters}
          >
            {columns.map((col, i) => (
              <Column
                key={i}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                hidden={col.hidden}
                body={col.body}
              />
            ))}
          </DataTable>
        </AccordionTab>
        <AccordionTab header='Config Teams'>
          <TeamsConfig />
        </AccordionTab>
      </Accordion>
    </>
  );
}
