import { useState } from "react";
import { SelectButton } from "primereact/selectbutton";

export default function SelectTransport({ onSelectChange }) {
  const [transport, setTransport] = useState("");

  const transportationOptions = [
    { name: "Camp Bus 🚌", value: "Bus" },
    { name: "By Car 🚗", value: "Car" },
    { name: "Public Transportation 🚃", value: "Public transportation" },
  ];

  const handleSelectChange = (e) => {
    setTransport(e.value);
    onSelectChange(e.value); // Call the callback function with the selected value
  };

  return (
    <SelectButton
      value={transport}
      options={transportationOptions}
      onChange={handleSelectChange}
      optionLabel='name'
      className='center-selection'
    />
  );
}
