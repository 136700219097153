import React from "react";
import { exportPDF } from "../../utils/export.js";
import { Button } from "primereact/button";

export default function ExportPDFButton({value, fileName }) {
  return (
    <Button
      label='PDF'
      icon='pi pi-file-pdf'
      className='btn-pdf'
      onClick={() => exportPDF(value, fileName)}
    />
  );
}
