import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { getTeamsList } from "@db/teams.js";

export default function SelectTeams({ onSelectChange, rowData }) {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamData, setTeamData] = useState([]);

  const handleSelectChange = (e) => {
    if (typeof e.value === "object") e.value = null;
    setSelectedTeam(e.value);
    onSelectChange(e.value);
  };

  useEffect(() => {
    const fetchTeamData = async () => {
      const data = await getTeamsList();
      const arrayData = [];
      arrayData.push({
        name: "Unassigned",
        value: undefined,
      });
      data.map((u) => {
        const d = {
          name: u.name,
          value: u.id,
        };
        arrayData.push(d);
      });
      setTeamData(arrayData);
    };
    fetchTeamData();
  }, []);

  return (
    <>
      <p className='text-center text-white'>
        Select teams for
        <br />
        {rowData.name}
      </p>
      <Dropdown
        value={selectedTeam}
        options={teamData}
        optionLabel='name'
        onChange={handleSelectChange}
        placeholder='Unassigned'
      />
    </>
  );
}
