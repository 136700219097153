import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { getRoomInfo } from "@db/rooms.js";

export default function SelectRooms({ onSelectChange, rowData }) {
  const [roomData, setRoomData] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [placeHolder, setPlaceHolder] = useState("Select room");

  useEffect(() => {
    const fetchRoomData = async () => {
      placeholderChange(rowData.room_id);
      const data = await getRoomInfo();
      const arrayData = [];
      arrayData.push({
        name: "Unassigned",
        value: undefined,
      });
      data.map((u) => {
        const d = {
          name: u.name + " (capacity " + u.capacity + ")",
          value: u.id,
        };
        arrayData.push(d);
      });
      setRoomData(arrayData);
    };
    fetchRoomData();
  }, []);

  const handleSelectChange = (e) => {
    if (typeof e.value === "object") e.value = null;
    placeholderChange(e.value);
    setSelectedRoom(e.value);
    onSelectChange(e.value);
  };

  const placeholderChange = (value) => {
    if (value === null) setPlaceHolder("Select room");
    else setPlaceHolder("Unassign");
  };

  return (
    <>
      <p className='text-center text-white'>
        Select room for
        <br />
        {rowData.name}
      </p>
      <Dropdown
        value={selectedRoom}
        options={roomData}
        optionLabel='name'
        onChange={handleSelectChange}
        placeholder='Unassigned'
      />
    </>
  );
}
