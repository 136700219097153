import { formatLocalDateUS } from "./dateTime.js";
import { getPricingInfoById } from "@db/pricing.js";
import {
  getFullNameFromUserById,
  getUserForWebhookRegistration,
} from "@db//users.js";
import { getRoomInfoUsers } from "@db/rooms.js";
import { calculateAgeByDate } from "./getAge.js";
import { DeveloperMode } from "../config/DeveloperMode.js";
import { getCurrentUserIdNameEmail } from "@db/currentUser.js";
import { authValid } from "@utils/auth.js";

const sendToDiscord = async (
  webhookUrl,
  embedTitle,
  embedDescription,
  color,
  timestamp
) => {
  if(DeveloperMode()) return;
  try {
    const response = await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content: "",
        tts: false,
        embeds: [
          {
            type: "rich",
            title: embedTitle,
            description: embedDescription,
            color: color,
            timestamp: timestamp,
          },
        ],
      }),
    });

    if (!response.ok) {
      throw new Error(
        `Discord webhook responded with status: ${response.status} ${response.statusText}`
      );
    }

    return response.status + " " + response.statusText;
  } catch (error) {
    console.error(`Error in sending data to Discord: ${error.message}`);
    throw error;
  }
};

export const DiscordWebhookSendForm = async (params) => {
  const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_URL_FORMS;
  params.birthday = formatLocalDateUS(params.birthday);
  const pricingInfo = await getPricingInfoById(params.pricing_id);
  const parentName = params.parent_id
    ? await getFullNameFromUserById(params.parent_id)
    : null;
  const age = calculateAgeByDate(params.birthday);

  const title = `${params.first_name} ${params.last_name}`;
  const description =
    `Birthday: ${params.birthday}\n` +
    `${params.phone ? `Phone: ${params.phone}\n` : ""}` +
    `Age: ${age}\n` +
    `Gender: ${params.gender}\n` +
    `Email: ${params.email}\n` +
    `Country: ${params.country}\n` +
    `Church: ${params.church_mission}\n` +
    `Shirt size: ${params.size}\n` +
    `Member: ${params.member}\n` +
    `Dates: ${params.dates}\n` +
    `Arrival_transportation: ${params.arrival_transportation}\n` +
    `Departure_transportation: ${params.departure_transportation}\n` +
    `Accommodation: ${params.accommodation}\n` +
    `Price name: ${pricingInfo.name}\n` +
    `Price amount: ${pricingInfo.price} DKK\n` +
    `${parentName ? "Submitted by: " + parentName : ""}`;

  return await sendToDiscord(
    webhookUrl,
    title,
    description,
    setColorCode(params.pricing_id),
    new Date().toISOString()
  );
};

export const DiscordWebhookSendChargePayment = async (params) => {
  const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_URL_PAYMENT;
  const inputDate = new Date(params.created_at).toISOString();
  const payedFor = await getFullNameFromUserById(params.payed_for_user_id);
  const payedBy = await getFullNameFromUserById(params.payed_by_user_id);
  const pricingInfo = await getPricingInfoById(params.pricing_id);

  const title = `Successfully paid for ${payedFor}`;
  const description =
    `Amount: ${params.amount}\n` +
    `Price name: ${pricingInfo.name}\n` +
    `Price amount: ${pricingInfo.price} DKK\n` +
    `Type: ${params.type}\n` +
    `Payment method: ${params.payment_method}\n` +
    `${
      payedBy === payedFor
        ? ""
        : `Paid by: ${payedBy}\nIs anonymous: ${params.is_anonymous}`
    }\n` +
    `Payment ID: ${params.id}\n`;

  return await sendToDiscord(
    webhookUrl,
    title,
    description,
    setColorCode(2),
    inputDate
  );
};

export const DiscordWebhookSendRegistration = async (uuid) => {
  try {
    const user = await getUserForWebhookRegistration(uuid);
    const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_REGISTERED;
    if (!webhookUrl)
      throw new Error("Webhook URL is not defined in environment variables.");

    const inputDate = new Date(user.registered_at).toISOString();

    const age = calculateAgeByDate(user.birthday);

    let totalRoomsLeft = null;
    let colorCode = null;
    if (user.accommodation === "Room") {
      const roomInfo = await getRoomInfoUsers();
      if (roomInfo.fully_booked) {
        totalRoomsLeft = "No rooms left";
        colorCode = setColorCode(1);
      } else {
        totalRoomsLeft = `Total rooms left: ${roomInfo.available}`;
        colorCode = setColorCode(2);
      }
    } else colorCode = setColorCode(3);

    const title = `${user.first_name} ${user.last_name} has registered`;
    const description =
      `Camper number: ${user.camperNumber}\n` +
      `Age: ${age}\n` +
      `Price name: ${user.pricing.name}\n` +
      `Price amount: ${user.pricing.price} DKK\n` +
      `Accommodation: ${user.accommodation}\n` +
      `${totalRoomsLeft ? `${totalRoomsLeft}` : ""}`;

    return await sendToDiscord(
      webhookUrl,
      title,
      description,
      colorCode,
      inputDate
    );
  } catch (error) {
    console.error(`Error in DiscordWebhookSendRegistration: ${error.message}`);
    throw error;
  }
};

export const DiscordWebhookVisitorDetected = async () => {
  try {
    const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_VISITOR;
    if (!webhookUrl)
      throw new Error("Webhook URL is not defined in environment variables.");

    const inputDate = new Date().toISOString();

    const { data, dataGeocode } = await fetchClientInfo();

    let color;
    if (data.device === "Spidey") color = setColorCode(6);
    if (dataGeocode.countryName.includes("Denmark")) {
      color = setColorCode(1);
    } else if (dataGeocode.countryName === "Sweden") {
      color = setColorCode(3);
    } else if (dataGeocode.countryName.includes("Netherlands")) {
      color = setColorCode(11);
    } else if (dataGeocode.countryName.includes("Spain")) {
      color = setColorCode(4);
    } else if (dataGeocode.countryName.includes("Finland")) {
      color = setColorCode(100);
    } else if (dataGeocode.countryName.includes("United States of America")) {
      color = setColorCode(9);
    } else {
      color = setColorCode(8);
    }

    const title = "Visitor detected from " + dataGeocode.countryName;
    const description =
      `IP: ${data.ipString}\n` +
      `Device: ${data.device}\n` +
      `OS: ${data.os}\n` +
      `Behind proxy: ${data.isBehindProxy}\n` +
      `Mobile: ${data.isMobile}\n` +
      `userAgent: ${data.userAgent}\n` +
      `Browser: ${data.userAgentRaw}\n` +
      `Browser display: ${data.userAgentDisplay}\n` +
      `City: ${dataGeocode.city}`;

    return await sendToDiscord(
      webhookUrl,
      title,
      description,
      color,
      inputDate
    );
  } catch (error) {
    console.error(`Error in DiscordWebhookVisitorDetected: ${error.message}`);
    throw error;
  }
};

export const DiscordWebhookLoginDetected = async () => {
  try {
    const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_LOGIN;
    if (!webhookUrl)
      throw new Error("Webhook URL is not defined in environment variables.");

    const inputDate = new Date().toISOString();

    console.log("getCurrentUserFromSupabase");
    const userData = await getCurrentUserIdNameEmail();
    const name = userData.first_name + " " + userData.last_name;

    const { data, dataGeocode } = await fetchClientInfo();

    const session = await authValid();

    const title = `${name} has logged in`;
    const description =
      `Email: ${userData.email}\n` +
      `User ID: ${userData.id}\n` +
      `Provider: ${session.user.app_metadata.provider}\n` +
      `IP: ${data.ipString}\n` +
      `Device: ${data.device}\n` +
      `OS: ${data.os}\n` +
      `Behind proxy: ${data.isBehindProxy}\n` +
      `Mobile: ${data.isMobile}\n` +
      `userAgent: ${data.userAgent}\n` +
      `Browser: ${data.userAgentRaw}\n` +
      `Browser display: ${data.userAgentDisplay}\n` +
      `Country: ${dataGeocode.countryName}\n` +
      `City: ${dataGeocode.city}`;

    let color = setColorCode(0);

    if (dataGeocode.countryName.includes("Denmark")) {
      color = setColorCode(1);
    } else if (dataGeocode.countryName === "Sweden") {
      color = setColorCode(3);
    } else if (dataGeocode.countryName.includes("Netherlands")) {
      color = setColorCode(11);
    } else if (dataGeocode.countryName.includes("Spain")) {
      color = setColorCode(4);
    } else if (dataGeocode.countryName.includes("Finland")) {
      color = setColorCode(100);
    } else if (dataGeocode.countryName.includes("United States of America")) {
      color = setColorCode(9);
    } else {
      color = setColorCode(8);
    }

    return await sendToDiscord(
      webhookUrl,
      title,
      description,
      color,
      inputDate
    );
  } catch (error) {
    console.error(`Error in DiscordWebhookLoginDetected: ${error.message}`);
    throw error;
  }
};

const setColorCode = (id) => {
  switch (id) {
    case 0:
      return 0x2ecc71; // Vivid green
    case 1:
      return 0xff5733; // Vibrant red
    case 2:
      return 0x33ff57; // Bright green
    case 3:
      return 0x2a8df6; // Vivid blue
    case 4:
      return 0xf1c40f; // Lively yellow
    case 5:
      return 0x9b59b6; // Rich purple
    case 6:
      return 0x34495e; // Deep navy
    case 7:
      return 0xe74c3c; // Strong orange-red
    case 8:
      return 0x45e4c2; // Turkish blue
    case 9:
      return 0x0008ff; // Dark sky blue
    case 10:
      return 0x7f8c8d; // Muted gray
    case 11:
      return 0xff9130; // Orange
    default:
      return 0xffffff; // Default case, white
  }
};

const fetchClientInfo = async () => {
  const responseIp = await fetch(
    "https://api.bigdatacloud.net/data/client-info"
  );
  const responseGeocode = await fetch(
    "https://api.bigdatacloud.net/data/reverse-geocode-client"
  );
  const data = await responseIp.json();
  const dataGeocode = await responseGeocode.json();

  return { data, dataGeocode };
};
