import { Button } from "primereact/button";
import { useState, useEffect } from "react";
import "@styles/paymentChoice.css";
import { getIsDeadlinePassed } from "@utils/globalVariables.js";

export function PaymentChoice({ handleChoice, isCurrentUserRegistered }) {
  const [label, setLabel] = useState("Pay for myself");

  useEffect(() => {
    if (!isCurrentUserRegistered) setLabel("Pay for myself (complete)");
  });

  return (
    <div className='payment-choice-container'>
      <h1>Choose Payment</h1>
      {/* <h2>New Deadline</h2> */}
      <p>
        Registration deadline: April 21, 2024. After this date preferences or special
        requests will not be taken into account
      </p>
      <p>
        Payment deadline: April 30, 2024. After this date, room assignment and right
        T-shirt size are not guaranteed.
      </p>
      {getIsDeadlinePassed() && (
        <p>
          Deadline has passed, so there's uncertainty regarding obtaining a
          T-shirt in your preferred size or securing a room for accommodation.
          Additionally, all payments must now be paid in full.
        </p>
      )}
      <Button
        className='prime'
        label={label}
        severity='success'
        icon='pi pi-user'
        onClick={() => handleChoice("Pay for myself")}
        disabled={!isCurrentUserRegistered}
      />
      <Button
        className='prime'
        label='Pay for another person'
        severity='success'
        icon='pi pi-users'
        onClick={() => handleChoice("Pay for someone else")}
      />
      {/* <Button
        className='button'
        label='Add donation (Not available for now)'
        severity='success'
        icon='pi pi-heart'
        onClick={() => handleChoice("Donation")}
        disabled
      /> */}
    </div>
  );
}
