export const formatReadableNumber = (number) => {
  let formattedNumber = number.toLocaleString('en-US', { maximumFractionDigits: 2 });

  // Remove decimal part if it's '.00'
  if (formattedNumber.includes('.00')) {
    formattedNumber = formattedNumber.replace('.00', '');
  } 
  
  // Add thousand separators for the integer part
  const parts = formattedNumber.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  formattedNumber = parts.join(".");

  return formattedNumber;
};
