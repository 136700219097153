import { React, useState } from "react";
import SignUp from "../components/SignUp";
import "../styles/signup.css";

export default function Register() {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showMagicLink, setShowMagicLink] = useState(true);
  const [showInt, setShowInt] = useState(1);

  const show = () => {
    if (showInt === 0) {
      setShowSignUp(false);
      setShowInt(1);
    } else if (showInt === 1) {
      setShowMagicLink(false);
      setShowSignUp(true);
      setShowInt(0);
    }
  };

  return (
    <>
      <div className='full-screen-container'>
          <SignUp />
      </div>
    </>
  );
}
