export const getColor = (index) => {
  switch (index) {
    case 0:
      return "#EC6765"; // Red
    case 1:
      return "#fde74c"; // Yellow
    case 2:
      return "#5bc0eb"; // Sky Blue
    case 3:
      return "#A4D046"; // Light Green
    case 4:
      return "#d782ba"; // Pink
    case 5:
      return "#A500FF"; // Purple
    case 6:
      return "#ff8c42"; // Orange
    case 7:
      return "#00b159"; // Green
    case 8:
      return "#08E8E8"; // Cyan
    case 9:
      return "#00aedb"; // Cerulean
    case 10:
      return "#f37735"; // Orange (Lighter)
    case 11:
      return "#7bc043"; // Apple Green
    case 12:
      return "#0392cf"; // Blue (Cerulean)
    case 13:
      return "#ee4035"; // Red (Bright)
    case 14:
      return "#851e3e"; // Burgundy
    case 15:
      return "#fed766"; // Yellow (Saffron)
    case 16:
      return "#009688"; // Teal
    case 17:
      return "#fe4a49"; // Red (Soft)
    case 18:
      return "#2ab7ca"; // Blue (Soft)
    case 19:
      return "#fedcd2"; // Pink (Soft)
    case 20:
      return "#e6e6ea"; // Lavender (Soft)
    case 21:
      return "#b1cbbb"; // Green (Pastel)
    case 22:
      return "#e1a692"; // Salmon
    case 23:
      return "#92a8d1"; // Blue (Pastel)
    case 24:
      return "#9c9f84"; // Olive (Soft)
    default:
      return "#EC6765"; // Default to the first color
  }
};


export function adjustColor(hex, amount) {
  // Remove the '#' character from the hex color if it's present
  hex = hex.replace(/^#/, "");

  // Parse the hex color to get the RGB components
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Adjust each color component by the specified amount
  r = Math.min(255, Math.max(0, r + amount));
  g = Math.min(255, Math.max(0, g + amount));
  b = Math.min(255, Math.max(0, b + amount));

  // Combine the adjusted components back into a hex color
  return "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");
}


export function calculateTextColor(backgroundHex) {
  // Convert hex to RGB
  const hexToRgb = (hex) =>
      hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
          ,(m, r, g, b) => '#' + r + r + g + g + b + b)
          .substring(1)
          .match(/.{2}/g)
          .map(x => parseInt(x, 16));

  const [r, g, b] = hexToRgb(backgroundHex);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Decide the appropriate text color
  const textColor = luminance > 0.5 ? '#000000' : '#FFFFFF';

  return {
      hex: textColor,
      rgb: `rgb(${r}, ${g}, ${b})`,
      rgba: `rgba(${r}, ${g}, ${b}, 1)`, // default alpha value is 1
      luminance: luminance
  };
}

export function rgbToHex(rgb) {
  // Split the RGB string into an array of individual color values
  const values = rgb.match(/\d+/g);
  
  // Convert each color value to hexadecimal and pad with zeros if necessary
  const hexValues = values.map((value) => {
      const hex = parseInt(value).toString(16); // Convert decimal to hexadecimal
      return hex.length === 1 ? '0' + hex : hex; // Pad with zero if necessary
  });
  
  // Concatenate the hexadecimal values to form the final hex color string
  const hexColor = '#' + hexValues.join('');
  
  return hexColor.toUpperCase(); // Convert to uppercase for consistency
}