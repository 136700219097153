import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "primereact/divider";
import "../../styles/profile.css";
import supabase from "../../config/supabase";
import "../../styles/button.css";
import { formatLocalDateUS } from "../../utils/dateTime.js";
import EditButton from "@components/buttons/EditButton.js";
import SelectTransport from "../form/SelectTransport.js";
import SelectDays from '../form/SelectDays.js';
import SelectSize from '../form/SelectSize.js';
import {calculateAgeByDate} from '@utils/getAge.js'

export default function ProfileInfo() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [church, setChurch] = useState("");
  const [shirtSize, setShirtSize] = useState("");
  const [member, setMember] = useState("");
  const [uuid, setUuid] = useState(null);
  const [phone, setPhone] = useState("");
  const [dates, setDates] = useState([]);
  const [arrival_transportation, setArrival_transportation] = useState("");
  const [departure_transportation, setDeparture_transportation] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const age = useRef(0)

  const navigation = useNavigate();

  const [userData, setUserData] = useState(null);

  const countryCodeConverter = new Intl.DisplayNames(["en"], {
    type: "region",
  });

  const getUuid = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    setUuid(user.id);
    getUserData(user.id);
  };

  useEffect(() => {
    getUuid();
    localStorage.setItem("activeTab", 0);
  }, []);

  const getUserData = async (uuid) => {
    const { data, error } = await supabase
      .from("users")
      .select()
      .eq("id", uuid);
    if (error) {
      console.log("error", error);
      return;
    }
    if (data === null || data.length === 0) {
      navigation("/form");
    } else {
      getData(data[0]);
    }
  };



  const getData = (data) => {
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setBirthday(formatLocalDateUS(data.birthday));
    setEmail(data.email);
    setCountry(countryCodeConverter.of(data.country));
    setChurch(data.church_mission);
    setShirtSize(data.size);
    setDates(data.dates);
    setArrival_transportation(data.arrival_transportation);
    setDeparture_transportation(data.departure_transportation);
    setAccommodation(data.accommodation);
    age.current = calculateAgeByDate(data.birthday);
    if (data.member) {
      setMember("Yes");
    } else {
      setMember("No");
    }
    if (data.phone === null) {
      setPhone("Not Provided");
    } else {
      setPhone(data.phone);
    }
  };

  return (
    <>
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Full name:</b> {firstName} {lastName}
        </p>
      </div>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Birthday:</b> {birthday}
        </p>
      </div>
      <Divider />
      <p className='profile-info'>
        <b>Email:</b> {email}
      </p>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Phone nr:</b> {phone}
        </p>
      </div>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Country:</b> {country}
        </p>
      </div>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Church: </b>
          {church}
        </p>
      </div>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Member:</b> {member}
        </p>
      </div>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Shirt Size:</b> {shirtSize}
        </p>
        <EditButton reload={true} uuid={uuid} updateData={{ size: null }}>
          <SelectSize edit age={age}/>
        </EditButton>
      </div>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Attending dates:</b>{" "}
          {dates.length == 5
            ? "All "
            : dates.map((date, index) => {
                return index === dates.length - 1 ? date : date + ", ";
              })}
        </p>
        <EditButton reload={true} uuid={uuid} updateData={{ dates: null }}>
          <SelectDays />
        </EditButton>
      </div>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Arrival Transportation:</b> {arrival_transportation}
        </p>
        <EditButton reload={true} uuid={uuid} updateData={{ arrival_transportation: null }}>
          <SelectTransport />
        </EditButton>
      </div>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Departure Transportation:</b> {departure_transportation}
        </p>
        <EditButton reload={true} uuid={uuid} updateData={{ departure_transportation: null }}>
          <SelectTransport />
        </EditButton>
      </div>
      <Divider />
      <div className='row-profile'>
        <p className='profile-info'>
          <b>Accommodation:</b> {accommodation}
        </p>
      </div>
      <Divider />
      <p className='profile-info'>
        <b>Uuid:</b> {uuid}
      </p>
    </>
  );
}
