import axios from "axios";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabase.js";
import { getPricingIdByName } from "@/db/pricing.js";
import {
  setUsersNotRegisteredRoomsToStayIn,
  resetAllUsersRegistrationAndPayments,
} from "@/db/users.js";
import { Button } from "primereact/button";
import { getUrlLocationOrigin } from "../utils/locationUrl.js";
import { getUserInfoForEmail } from "@/db/users.js";
import { getCurrentUserFromSupabase } from "@db/currentUser.js";
import { DiscordWebhookSendRegistration } from "@utils/discordWebhooks.js";

export default function Test() {
  const nav = useNavigate();

  const handlegetPricingIdByName = async () => {
    const data = await getPricingIdByName("Senior");
  };

  const handleUrl = () => {
    const url = getUrlLocationOrigin();
    console.log(url);
  };

  const handleUpdate = async () => {
    await setUsersNotRegisteredRoomsToStayIn();
  };

  const resetRegistration = async () => {
    await resetAllUsersRegistrationAndPayments();
    alert("Reset Complete");
  };

  const sendEmail = async () => {
    const user = await getCurrentUserFromSupabase();
    const userdataForEmail = await getUserInfoForEmail(user.id);

    const { data, error } = await supabase.functions.invoke("send-receipt", {
      body: {
        userdataForEmail,
      },
    });
  };

  const sendWebhookRegistration = async () => {
    const user = await getCurrentUserFromSupabase();
    const response = await DiscordWebhookSendRegistration(user.id);
    if (response) {
      alert("Webhook sent");
    } else {
      alert("Webhook failed");
    }
  };

  return (
    <div className='center'>
      <div className='buttons-column-center '>
        <h1 style={{ color: "white" }}>Test site</h1>
        <Button label='Get Pricing' onClick={handlegetPricingIdByName} />
        <Button label='Get Url' onClick={handleUrl} />
        <Button label='Update name' onClick={handleUpdate} />
        <Button label='Send email' onClick={sendEmail} />
        <Button label='Reset Registration' onClick={resetRegistration} />
        <Button
          label='Send Webhook Registration'
          onClick={sendWebhookRegistration}
        />
      </div>
    </div>
  );
}
