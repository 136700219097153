import { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { getIsDeadlinePassed } from "@utils/globalVariables.js";

export function PaymentType({
  choice,
  setChoice,
  backButton,
  isDisabled,
  paymentTypeArray,
  placeholder,
  pricingId,
}) {
  const [paymentType, setPaymentType] = useState("");
  const [hasDeadlineExpired, setHasDeadlineExpired] = useState(true);

  const fullPriceObject = { name: "Full payment", value: "Full payment" };
  const firstPaymentObject = {
    name: "1/3 Payment",
    value: "First payment",
    disabled: hasDeadlineExpired,
  };
  // const firstPaymentObject = { name: "First Payment (Unavailable)", value: "First payment", disabled: true};
  const secondPaymentObject = {
    name: "2/3 Payment",
    value: "Second payment",
    disabled: hasDeadlineExpired,
  };
  const thirdPaymentObject = { name: "3/3 Payment", value: "Third payment" };
  const remainingAmountObject = {
    name: "Remaining amount",
    value: "Remaining amount",
  };

  useEffect(() => {
    setHasDeadlineExpired(getIsDeadlinePassed());

    if (isDisabled || !paymentTypeArray) return;
    let newPaymentTypeOptions = [];

    if (
      pricingId === 4 ||
      pricingId === 3 ||
      pricingId === 6 ||
      pricingId === 2
    ) {
      newPaymentTypeOptions.push(fullPriceObject);
    } else if (paymentTypeArray.includes("Second payment")) {
      newPaymentTypeOptions.push(thirdPaymentObject);
    } else if (paymentTypeArray.includes("First payment")) {
      newPaymentTypeOptions.push(secondPaymentObject, remainingAmountObject);
    } else {
      newPaymentTypeOptions.push(fullPriceObject, firstPaymentObject);
    }
    setPaymentType(newPaymentTypeOptions);
  }, [isDisabled, paymentTypeArray]);

  const paymentType_ = [
    {
      fullPriceObject,
      firstPaymentObject,
      secondPaymentObject,
      thirdPaymentObject,
      remainingAmountObject,
    },
  ];

  return (
    <>
      <Dropdown
        value={choice}
        options={paymentType}
        optionLabel='name'
        optionDisabled='disabled'
        onChange={(e) => setChoice(e.value)}
        placeholder={placeholder}
        disabled={isDisabled}
        style={{ width: "100%" }}
      />
      {/* <InputText placeholder='Discount code' />
      <Button label='Redeem' /> */}
      <Button
        className='prime'
        label='Back'
        severity='success'
        onClick={backButton}
        icon='pi pi-arrow-left'
        iconPos='left'
        style={{ width: "100%" }}
      />
    </>
  );
}
