import supabase from "@config/supabase.js";

const normalizeSize = (size) => {
  const babySizeMatch = size.match(/(\d+)\s*\(Baby size\)/);
  if (babySizeMatch) {
    return `${babySizeMatch[1]} (Baby size)`;
  }
  return size;
};

// Define a custom sorting function
export const sizeSorter = (a, b) => {
  const order = [
    "X-Small",
    "Small",
    "Medium",
    "Large",
    "X-Large",
    "XX-Large",
    "XXX-Large",
  ];
  const babySizeRegex = /(\d+)\s*\(Baby size\)/;

  const aMatch = a.label.match(babySizeRegex);
  const bMatch = b.label.match(babySizeRegex);

  if (aMatch && bMatch) {
    // If both are baby sizes, compare their numeric values
    return parseInt(aMatch[1], 10) - parseInt(bMatch[1], 10);
  } else if (aMatch) {
    return -1; // Baby sizes always come before adult sizes
  } else if (bMatch) {
    return 1; // Adult sizes always come after baby sizes
  }

  // Compare adult sizes based on predefined order
  return order.indexOf(a.label) - order.indexOf(b.label);
};

export const getAllSizesData = async () => {
  const { data, error } = await supabase
    .from("users")
    .select("first_name, last_name, size");

  if (error) {
    console.log("An error occurred in getAllSizesData:");
    console.log(error);
    return;
  }

  const groupedSizes = data.reduce((acc, currentUser) => {
    const normalizedSize = normalizeSize(currentUser.size);
    if (!acc[normalizedSize]) {
      acc[normalizedSize] = { label: normalizedSize, total: 0, names: [] };
    }
    acc[normalizedSize].names.push(
      `${currentUser.first_name} ${currentUser.last_name}`
    );
    acc[normalizedSize].total += 1;
    return acc;
  }, {});

  const sizesData = {
    sizes: Object.values(groupedSizes).sort(sizeSorter),
  };

  return sizesData;
};

export const getSizesColor = async () => {
  const { data, error } = await supabase
    .from("users")
    .select("first_name, last_name, size, team_id (name)")
    .eq("is_registered", true);

  if (error) {
    console.error("An error occurred in getSizesColor:", error);
    return;
  }

  const groupedByTeamAndSize = {};

  data.forEach((user) => {
    const normalizedSize = normalizeSize(user.size);
    const teamName = user.team_id ? user.team_id.name : "Unassigned";

    if (!groupedByTeamAndSize[teamName]) {
      groupedByTeamAndSize[teamName] = {};
    }

    if (!groupedByTeamAndSize[teamName][normalizedSize]) {
      groupedByTeamAndSize[teamName][normalizedSize] = { total: 0, people: [] };
    }

    groupedByTeamAndSize[teamName][normalizedSize].people.push(
      `${user.first_name} ${user.last_name}`
    );
    groupedByTeamAndSize[teamName][normalizedSize].total += 1;
  });

  // Sorting the sizes within each team
  Object.keys(groupedByTeamAndSize).forEach((teamName) => {
    const sizes = groupedByTeamAndSize[teamName];
    const sortedSizes = Object.entries(sizes).sort((a, b) =>
      sizeSorter({ label: a[0] }, { label: b[0] })
    );

    groupedByTeamAndSize[teamName] = sortedSizes.reduce(
      (acc, [sizeLabel, sizeData]) => {
        acc[sizeLabel] = sizeData;
        return acc;
      },
      {}
    );
  });

  return groupedByTeamAndSize;
};
