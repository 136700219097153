import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import supabase from "../config/supabase";
import { setPaymentParams } from "@db/payments.js";
import { getPricingIdByName } from "@db/pricing.js";
import { Image } from "primereact/image";
import { getFullNameFromUserById, getUserInfoForEmail } from "@db/users.js";
import checkmark from "../images/icons/check.png";
import { formatLocalDateTime } from "@utils/dateTime.js";
import { DeveloperMode } from "@/config/DeveloperMode.js";
import "../styles/checkout.css";
import { getTotalRooms } from "../utils/globalVariables.js";
import { LoadingData } from "@components/status/Loading.js";
import { DiscordWebhookSendRegistration } from "@utils/discordWebhooks.js";
import { sendReceipt } from "@api/sendReceipt.js";
import { PageSlideUp } from "@animations/PageSlide.js";

export default function Checkout() {
  const nav = useNavigate();
  const location = useLocation();
  // const [paymentId, setPaymentId] = useState("");
  const regex = /\?paymentid=(.*)/;

  const [isValidPaymentId, setIsValidPaymentId] = useState(false);
  const [isPaymentFull, setIsPaymentFull] = useState(false);
  const [loading, setLoading] = useState(true);

  //UseState
  const [paymentInfo, setPaymentInfo] = useState({});

  function isEmpty(value) {
    return (
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
  }

  let hasSentMail = false;

  useEffect(() => {
    const starting = async () => {
      const paymentId = location.search;
      if (isEmpty(paymentId)) {
        loadingDuration();
      } else {
        const match = regex.exec(paymentId);
        // console.log("first match", match[1]);
        handlePaymentId(match[1]);
      }
    };
    starting();
  }, []); // Empty dependency array ensures useEffect runs once on mount

  const handlePaymentId = async (paymentId) => {
    if (isEmpty(paymentId)) {
      return;
    }
    const { data, error } = await supabase.functions.invoke("payment-id", {
      body: {
        paymentId: paymentId,
        totalRooms: getTotalRooms(),
      },
    });
    if (error) {
      console.log("There was an error: ", error);
      return;
    }

    if (data.message === "Success") {
      setIsValidPaymentId(true);
      const paymentObject = data.data.payment;
      const orderItem1 = paymentObject.charges[0].orderItems[0];
      const orderItem2 = paymentObject.charges[0].orderItems[1];
      const priceName = paymentObject.orderDetails.reference;
      const pricingId = await getPricingIdByName(priceName);
      const anonymousDiscount = CheckIfAnonymousAndDiscount(
        orderItem2.reference
      );

      const uuidOfPayee = getUuidAndNameFromString(orderItem1.name);
      const uiidOfPayer = getUuidAndNameFromString(orderItem2.name);

      const name = await getFullNameFromUserById(uuidOfPayee);

      const dataObject = {
        id: paymentObject.paymentId,
        created_at: paymentObject.charges[0].created,
        amount: paymentObject.summary.chargedAmount / 100,
        payed_for_user_id: uuidOfPayee,
        payed_by_user_id: uiidOfPayer,
        payment_type: orderItem1.reference,
        pricing_id: pricingId,
        charge_id: paymentObject.charges[0].chargeId,
        is_anonymous: anonymousDiscount.isAnonymous,
        type: orderItem1.reference,
        name: name,
        payment_method: paymentObject.paymentDetails.paymentMethod,
      };

      setPaymentInfo(dataObject);

      if (data.data.is_registered) setIsPaymentFull(true);

      const addingPaymentStatus = await setPaymentParams(dataObject);

      if (
        !hasSentMail &&
        data.data.is_registered &&
        addingPaymentStatus &&
        !DeveloperMode()
      ) {
        hasSentMail = true;
        const userdataForEmail = await getUserInfoForEmail(
          dataObject.payed_for_user_id
        );

        await DiscordWebhookSendRegistration(dataObject.payed_for_user_id);
        console.log("Sending mail....");

        await sendReceipt(userdataForEmail);
      }
    } else {
      console.log("There was an error: ", data.message);
    }
    loadingDuration();
  };

  function CheckIfAnonymousAndDiscount(inputString) {
    const isNotAnonymous = /Not/i.test(inputString);

    // Check if numbers are present
    const hasNumbers = /\d/.test(inputString);

    // Output the results
    // console.log(`String: ${inputString}`);
    // console.log(`Not Anonymous: ${isNotAnonymous}`);
    // console.log(`Discount Used: ${hasNumbers}`);

    let numbersMatch = false;
    if (hasNumbers) {
      numbersMatch = inputString.match(/\d+/);
    }

    return {
      isAnonymous: !isNotAnonymous,
      discountCode: numbersMatch[0],
    };
  }

  const getUuidAndNameFromString = (str) => {
    let uuid = str.substring(0, 36); // Get the first 32 characters
    //let name = str.substring(37); // Get the characters from the 34th to the end

    return uuid;
  };

  const navigateToPage = (page) => {
    if (page === "/profile") {
      localStorage.setItem("activeTab", 1);
      nav(page);
    } else if (page === "/payment") {
      nav(page);
    }
  };

  const loadingDuration = () => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  return (
    <>
      <PageSlideUp isVisible={loading} style={{ overflow: "hidden" }}>
        <LoadingData 
        duration={3000}
        textArray={["Please do not leave this page", "Processing payment"]} />
      </PageSlideUp>
      {isValidPaymentId ? (
        <div className='bg-white p-8 rounded-lg text-center h-full my-16 checkout-payment-box'>
          {/* Replace 'checkmark' with your image path */}
          <div className='my-16 bg-blue'>
            <h2 className='text-2xl font-semibold text-gray-800 mb-4'>
              Successfully Paid
            </h2>
            <Image src={checkmark} alt='Checkmark' width='100' />{" "}
            <div className='border-t border-gray-200 mt-4 pt-4'>
              {/* Placeholder for payment information */}

              {isPaymentFull ? (
                <p>
                  Congratulation the person is now registered for our Church
                  Camp 2024, a receipt has been sent to the recipients email.
                </p>
              ) : (
                <p>
                  Note: They are still not registered since the person is not
                  paid full yet
                </p>
              )}
              <p>Payment for: {paymentInfo.name}</p>
              <p>Payment Amount: {paymentInfo.amount} DKK</p>
              <p>Payment Date: {formatLocalDateTime(paymentInfo.created_at)}</p>
              <p>Payment Type: {paymentInfo.type}</p>
              <p className='text-word-wrap'>Payment ID: {paymentInfo.id}</p>
            </div>
            <Button
              label='Profile page'
              onClick={() => navigateToPage("/profile")}
            />
            <Button
              label='Payment page'
              onClick={() => navigateToPage("/payment")}
            />
          </div>
        </div>
      ) : (
        <div className='bg-white p-8 rounded-lg text-center h-full my-16'>
          <h2 className='text-2xl font-semibold text-gray-800 mb-4'>
            Payment not found
          </h2>
          <p className='text-gray-700'>
            There was an error retriving your payment. Please try again.
          </p>
          <Button
            label='Profile page'
            onClick={() => navigateToPage("/profile")}
          />
          <Button
            label='Payment page'
            onClick={() => navigateToPage("/payment")}
          />
        </div>
      )}
    </>
  );
}
