import { ProgressSpinner } from "primereact/progressspinner";
import { useState, useEffect } from "react";
import "@styles/loading.css";
export default function Loading() {
  return (
    <section className='loading-section loading-fixed loading-bg-blue'>
      <div className='loading-box'>
        <ProgressSpinner
          style={{ width: "50px", height: "50px" }}
          strokeWidth='8'
          animationDuration='0.75s'
        />
        <p>Loading</p>
      </div>
    </section>
  );
}

export const LoadingData = ({ text, textArray }) => {
  if(!text) text = "Loading";
  const [loadingText, setLoadingText] = useState(text);

  useEffect(() => {
    if (text) {
      setLoadingText(text);
    } else if (textArray && textArray.length > 0) {
      setLoadingText(textArray[0]);
      let index = 0;
      const interval = setInterval(() => {
        setLoadingText(textArray[index]);
        if (index + 1 >= textArray.length) {
          clearInterval(interval);
        } else index++;
      }, 1000);
    } else {
      setLoadingText("Loading");
    }
  }, []);

  return (
    <section className='loading-fixed loading-bg-blue'>
      <div className='loading-box'>
        <ProgressSpinner
          style={{ width: "50px", height: "50px" }}
          strokeWidth='8'
          animationDuration='0.75s'
        />
        <p>{loadingText}</p>
      </div>
    </section>
  );
};

export const LoadingModule = ({ message }) => {
  return (
    <section className='loading-module box-column text-white text-medium'>
      <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        strokeWidth='8'
        animationDuration='0.75s'
      />
      <p>{message}</p>
    </section>
  );
};

export const LoadingSpinner = () => {
  return (
    <section className='loading-spinner box-center-grid'>
      <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        strokeWidth='8'
        animationDuration='0.75s'
      />
    </section>
  );
}
