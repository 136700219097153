import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import "../styles/profile.css";
import { Button } from "primereact/button";
import supabase from "../config/supabase";
import AddCamperTab from "../components/tabs/AddCamper";
import "../styles/button.css";
import ProfileInfo from "../components/tabs/ProfileInfo";
import RoomsInfo from "../components/tabs/RoomsInfo";
import TeamInfo from "../components/tabs/TeamInfo";
import PaymentInfo from "../components/tabs/PaymentInfo.js";
import ProfileSettings from "../components/tabs/ProfileSettings.js";
import { getActiveIndexTab, setActiveIndexTab } from "@utils/activeTab";

export default function Profile(active) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [church, setChurch] = useState("");
  const [shirtSize, setShirtSize] = useState("");
  const [member, setMember] = useState("");
  const [uuid, setUuid] = useState(null);
  const [phone, setPhone] = useState("");
  const [dates, setDates] = useState([]);
  const [arrival_transportation, setArrival_transportation] = useState("");
  const [departure_transportation, setDeparture_transportation] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const [payment_method, setPayment_method] = useState("");
  const [currentUsersData, setCurrentUsersData] = useState([]);

  const [activeIndex, setActiveIndex] = useState(null);
  const navHome = useNavigate();

  const countryCodeConverter = new Intl.DisplayNames(["en"], {
    type: "region",
  });

  useEffect(() => {
    const getUuid = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user === null) return navHome("/");
      // LS.setUserData("id", user.id);
      setUuid(user.id);
      getUserData(user.id);
    };
    getUuid();

    getActiveIndexTab("activetab", setActiveIndex);
  }, []);

  const getUserData = async (uuid) => {
    const { data, error } = await supabase
      .from("users")
      .select()
      .eq("id", uuid);
    if (error) {
      console.log("error", error);
      return;
    }
    if (data === null || data.length === 0) {
      navHome("/form");
    } else {
      // LS.setUserData(data[0]);
      setFirstName(data[0].first_name);
      setLastName(data[0].last_name);
      setBirthday(data[0].birthday);
      setEmail(data[0].email);
      setCountry(countryCodeConverter.of(data[0].country));
      setChurch(data[0].church_mission);
      setShirtSize(data[0].size);
      setDates(data[0].dates);
      setArrival_transportation(data[0].arrival_transportation);
      setDeparture_transportation(data[0].departure_transportation);
      setAccommodation(data[0].accommodation);
      setPayment_method(data[0].payment_method);
      if (data[0].member === "true") {
        setMember("Yes");
      } else {
        setMember("No");
      }
      if (phone.length <= 1) {
        //because phone === null will return false
        setPhone("Not provided");
      } else {
        setPhone(localStorage.getItem("phone"));
      }
      setCurrentUsersData(data[0]);
    }
  };

  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div>
      <Button
        label='No'
        icon='pi pi-times'
        onClick={() => setVisible(false)}
        className='p-button-text'
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );

  return (
    <>
      <div className='profile-container'>
        <TabView
          className='profile-center'
          activeIndex={activeIndex}
          onTabChange={(e) => {
            setActiveIndexTab("activetab", e.index);
            setActiveIndex(e.index);
          }}
        >
          <TabPanel header='Profile'>
            <ProfileInfo />
          </TabPanel>
          <TabPanel header='Payment' className='profile-payment-tab'>
            <PaymentInfo currentUsersData={currentUsersData} />
          </TabPanel>
          <TabPanel header='Add camper'>
              <AddCamperTab />
          </TabPanel>
          {/* <TabPanel header='My Team' leftIcon='pi pi-users'>
            <TeamInfo />
            <p>You are not assign in any team at this moment</p>
          </TabPanel>
          <TabPanel header='Room' leftIcon='pi pi-home'>
            <RoomsInfo />
          </TabPanel> */}
          <TabPanel header='Settings'>
            <ProfileSettings />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}
