import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

export default function RedeemCode({onSelectChange}) {
  const rabbitWhole = "K@mp&2o24";

  const [codeValue, setCodeValue] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Redeem");
  const [buttonStyle, setButtonStyle] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  

  const redeemCode = async () => {
    setLoading(true);
    setButtonLabel("Verifying...");
    setButtonStyle("loading-button");
    setIsDisabled(true);

    setTimeout(() => {
      if (codeValue === rabbitWhole) {
        setButtonStyle("success-button");
        setIsDisabled(true);
        setButtonLabel("Code redeemed");
        onSelectChange(true);
        setCodeValue("Success 🎉");
      } else {
        setButtonStyle("error-button");
        setButtonLabel("Invalid code");
      }
      setLoading(false);
      setIsDisabled(false);
    }, 4000);
  };

  return (
    <>
      <div className='center center-selection'>
        <InputText
          disabled={isDisabled}
          value={codeValue}
          onChange={(e) => setCodeValue(e.target.value)}
          placeholder='Promo code'
        />
        <Button
          disabled={isDisabled}
          label={buttonLabel}
          severity='pi success'
          onClick={redeemCode}
          className={buttonStyle}
          loading={loading}
        />
      </div>
    </>
  );
}
