import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";

export const registerTag = (rowData) => {
  let isRegistered = false;
  if (rowData.is_registered === undefined) {
    rowData ? (isRegistered = true) : (isRegistered = false);
  }
  if (rowData.is_registered === "Yes") isRegistered = true;
  return (
    <Tag
      value={isRegistered ? "Registered" : "Not Registered"}
      className={`tag-small tag-style ${
        isRegistered ? colorTag("Green") : colorTag("Red")
      }`}
    ></Tag>
  );
};

const colorTag = (color) => {
  switch (color) {
    case "Blue": {
      return "tag-blue";
    }
    case "Red": {
      return "tag-red";
    }
    case "Green": {
      return "tag-green";
    }
    case "Black": {
      return "tag-black";
    }
    case "White": {
      return "tag-white";
    }
    default: {
      return "tag-not-assigned";
    }
  }
};

const getSeverity = (status) => {
  switch (status) {
    case "unqualified":
      return "danger";

    case "qualified":
      return "success";

    case "new":
      return "info";

    case "negotiation":
      return "warning";

    case "renewal":
      return null;
  }
};

export const statusRowFilterTemplate = (options) => {
  console.log(options);
  const [statuses] = [
    "unqualified",
    "qualified",
    "new",
    "negotiation",
    "renewal",
  ];

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  return (
    <Dropdown
    //   value={options.value}
    //   options={statuses}
    //   onChange={(e) => options.filterApplyCallback(e.value)}
    //   itemTemplate={statusItemTemplate}
    //   placeholder='Select One'
    //   className='p-column-filter'
    //   showClear
    //   style={{ minWidth: "12rem" }}
    />
  );
};
