import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getAllPayments } from "@db/payments.js";
import { formatLocalDateTime } from "@utils/dateTime.js";
import { Button } from "primereact/button";
import { getTotalPayments } from "@db/payments.js";
import { getTotalPriceAmountOfAllUsers } from "@db/users.js";
import { ProgressBar } from "primereact/progressbar";
import { Accordion } from "primereact/accordion";
import { AccordionTab } from "primereact/accordion";
import { formatReadableNumber } from "@utils/numbers.js";
import ExportExcelButton from "@components/buttons/ExportExcelButton.js";

export default function PaymentAdmin() {
  const [paymentData, setPaymentData] = useState([]);
  const [totalPayments, setTotalPayments] = useState(0);
  const [totalPriceAmount, setTotalPriceAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [totalMissing, setTotalMissing] = useState(0);

  useEffect(() => {
    const fetchPayments = async () => {
      const payments = await getAllPayments();
      const pList = payments.map((p) => {
        return {
          name: p.payedFor.first_name + " " + p.payedFor.last_name,
          created_at: formatLocalDateTime(p.created_at),
          amount_paid: p.amount,
          type: p.type,
          price_name: p.pricing.name,
          price_amount: p.pricing.price,
          is_anonymous: p.is_anonymous,
          payed_by: p.payedBy.first_name + " " + p.payedBy.last_name, // Assuming you want to use this combined name
          payment_method: p.payment_method,
          id: p.id,
        };
      });
      pList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setPaymentData(pList);
    };
    const fetchPaymentsTotal = async () => {
      const total = await getTotalPayments();
      const totalPrice = await getTotalPriceAmountOfAllUsers();
      const percentageProgress = (
        (Number(total) / Number(totalPrice)) *
        100
      ).toFixed(2);
      setPercentage(formatReadableNumber(percentageProgress));
      setTotalPayments(formatReadableNumber(Number(total).toFixed(2)));
      setTotalPriceAmount(formatReadableNumber(Number(totalPrice).toFixed(2)));
      setTotalMissing(
        formatReadableNumber(Number(totalPrice - total).toFixed(2))
      );
    };
    fetchPayments();
    fetchPaymentsTotal();
  }, []);

  const columns = [
    { field: "name", header: "Name", sortable: true, size: "small" },
    { field: "created_at", header: "Date", sortable: true, size: "small" },
    { field: "amount_paid", header: "Amount", sortable: true, size: "medium" },
    { field: "type", header: "Type", sortable: true, size: "small" },
    {
      field: "price_name",
      header: "Price name",
      sortable: true,
      size: "medium",
    },
    { field: "price_amount", header: "Price", sortable: true, size: "medium" },
    { field: "payed_by", header: "Payed by", sortable: true, size: "small" },
    {
      field: "is_anonymous",
      header: "Anonymous",
      sortable: true,
      size: "small",
    },
    {
      field: "payment_method",
      header: "Payment method",
      sortable: true,
      size: "medium",
    },
    { field: "id", header: "ID", size: "large" },
  ];

  const valueTemplate = (value) => {
    return (
      <>
        {value}/<b>{100}</b>
      </>
    );
  };
  const dataChart = (
    <>
      <div style={{ padding: "50px 10%" }}>
        <div className='box-flex-wrap gap-20'>
          <p className='text-center tag-blue'>
            {totalPayments} DKK/ {totalPriceAmount} DKK
          </p>
        </div>
        <ProgressBar
          value={percentage}
          displayValueTemplate={valueTemplate}
          showValue={false}
        />
        <p className='text-black text-center'>{percentage}%</p>
        <div className='box-flex-wrap gap-20'>
          <p className='text-center tag-red'>Missing: {totalMissing} DKK</p>
        </div>
        <p className='text-black text-center'>
          <strong>Note:</strong> The total amount showed is not the real amount,
          since Nets takes transaction fees. <br />
          To check that please{" "}
          <a href='https://portal.dibspayment.eu/' className='navigate-text'>
            login to the Nets dashboard.
          </a>
        </p>
      </div>
    </>
  );

  const header = (
    <>
      <ExportExcelButton value={paymentData} fileName='camp_payments' />
    </>
  );

  return (
    <>
      {dataChart}
      <Accordion>
        <AccordionTab header='Payment history'>
          <DataTable
            value={paymentData}
            size='medium'
            scrollable
            selectionMode='single'
            showGridlines
            stripedRows
            scrollHeight='400px'
            className='admin-list-table'
            columnResizeMode='expand'
            resizableColumns
            header={header}
          >
            {columns.map((col, i) => {
              return (
                <Column
                  sortable={col.sortable}
                  key={i}
                  field={col.field}
                  header={col.header}
                  // style={
                  //   col.size === "small"
                  //     ? columnStyleSmall
                  //     : col.size === "medium"
                  //     ? columnStyleMedium
                  //     : columnStyleLarge
                  // }
                />
              );
            })}
          </DataTable>
        </AccordionTab>
      </Accordion>
    </>
  );
}
