import {getStartDate} from '@utils/globalVariables';

export const calculateAgeByDate = (birthday, date) => {
  const birthDate = new Date(birthday);
  if (date === undefined || date === null) {
    date = new Date();
  }
  let age = date.getFullYear() - birthDate.getFullYear();
  const monthDifference = date.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && date.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const isSharingBed = (birthday) => {
  const age = calculateAgeByDate(birthday);
  return age <= 7;
};

export const calculateAgeCategory = (birthday, gender, inactive) => {
  if(inactive) return "Inactive";
  const age = calculateAgeByDate(birthday, getStartDate());

  if (age >= 25) {
    return gender; // Assuming gender is 'Male' or 'Female'
  } else if (age >= 13) {
    return "Youth";
  } else if (age >= 4) {
    return "Children";
  } else {
    return "Toddler";
  }
};
