import supabase from "@config/supabase.js";

export const sendReceipt = async (userdataForEmail) => {
  const { data, error } = await supabase.functions.invoke("send-receipt", {
    body: {
      userdataForEmail,
    },
  });

  if (error) console.log(error);
  else console.log(data);
};
