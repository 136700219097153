import React from "react";

export default function Contact() {
  return (
    <section className='center text-center'>
      <h1 className='text-h1'>Contact info</h1>
      <div className='center text-left box-width box-white'>
        <div className='box-padding'>
          <p>Name: Copenhagen Bethel Missionary Baptist Church</p>
          <p>Email: cbmbchurch@gmail.com</p>
          <p>Camp email: camp.cbmbc@gmail.com</p>
          <p>Phone: +45 31238926</p>
          <p>Address: Lærdalsgade 7, 1. sal th, Copenhagen S Denmark</p>
          <p>
            Website:{" "}
            <a href='https://cbmbc.org' target='_blank'>
              cbmbc.org
            </a>
          </p>
          <p>
            CVR:{" "}
            <a
              target='_blank'
              href='https://datacvr.virk.dk/enhed/virksomhed/29998248?fritekst=29998248&sideIndex=0&size=10'
            >
              29998248{" "}
            </a>{" "}
          </p>
        </div>
      </div>
    </section>
  );
}
