import supabase from "@config/supabase.js";
import {getCurrentUserFromSupabase} from "@db/currentUser.js";

export const getAdminSettingsBool = async (settingName) => {
  const { data, error } = await supabase
    .from("admin_settings")
    .select("bool")
    .eq("name", settingName)
    .limit(1)
    .single();

  if (error) {
    console.error("Failed to get setting in getAdminSettingsBool");
    console.error(error.message);
    return null;
  }
  return data.bool;
};

export const setAdminSettingsBool = async (settingName, bool) => {
  const { error } = await supabase
    .from("admin_settings")
    .update({ bool: bool })
    .eq("name", settingName);
    console.log(bool)

  if (error) {
    console.error("Failed to update setting in setAdminSettingsBool");
    console.error(error.message);
    return false;
  }

  return true;
};

export const getAllAdminSettings = async () => {
  const { data, error } = await supabase
    .from("admin_settings")
    .select("name, bool, description,data_type,number_value, string_value")
    .order("id") // Sort by name;

  if (error) {
    console.error("Failed to get settings in getAllAdminSettings");
    console.error(error.message);
    return null;
  }

  return data;
};

export const getAdminSettings = async (settingNames) => {
  const { data, error } = await supabase
    .from("admin_settings")
    .select("name,bool,number_value,string_value")
    .in("name", settingNames)

  if (error) {
    console.error("Failed to get setting in getAdminSettings");
    console.error(error.message);
    return null;
  }

  console.log("🚀 ~ getAdminSettings ~ data:", data)
  return (
    data.reduce((acc, setting) => {
      acc[setting.name] = setting;
      return acc;
    }
    , {})
  );
}


export const checkIfUserIsAdmin = async (uuid) => {
  const { data, error } = await supabase
    .from("admin")
    .select("id")
    .eq("id", uuid);

  if (error) {
    console.error("An error occurred in checkIfUserIsAdmin: ", error.message);
    return null;
  }

  if (data.length > 0) {
    return true;
  }

  return false;
};


export const getAccessLevelOfCurrentUser = async () => {
    const user = await getCurrentUserFromSupabase();   
    const { data, error } = await supabase
        .from("admin")
        .select("access_level")
        .eq("id", user.id)
        .limit(1)
        .single();
    
    if (error) {
        console.error("An error occurred in getAccessLevel: ", error.message);
        return null;
    }
    
    return data.access_level;
    }