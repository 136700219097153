export const checkVariable = (variable) => {
  return !!variable;
};

export const checkVariableArray = (variables) => {
  const results = Map();

  variables.map((v) => {
    if (v === undefined || v === null || v === "") {
      console.log("Variable is undefined or null");
      results.set(v, false);
      return;
    } else results.set(v, true);

    return results;
  });
};

export const checkVariablesForm = (formData) => {
  const errorMessages = [];

  const fieldsToCheck = [
    { field: formData.first_name, message: "First name is not filled in" },
    { field: formData.last_name, message: "Last name is not filled in" },
    { field: formData.birthday, message: "Birthday is not filled in" },
    { field: formData.gender, message: "Gender has not been selected" },
    { field: formData.country, message: "Country has not been selected" },
    { field: formData.church, message: "Church/mission is not filled in" },
    { field: formData.size, message: "Shirt size has not been selected" },
    { field: formData.dates, message: "Dates has not been selected" },
    {
      field: formData.arrival_transportation,
      message: "Arrival transportation has not been selected",
    },
    {
      field: formData.departure_transportation,
      message: "Departure transportation has not been selected",
    },
    {
      field: formData.accommodation,
      message: "Accommodation has not been selected",
    },
  ];

  fieldsToCheck.forEach(({ field, message }) => {
    if (!checkVariable(field)) {
      errorMessages.push(message);
    }
  });

  if (formData.member == null) {
    errorMessages.push("Membership choice has not been selected");
  }

  if (formData.check === false) {
    errorMessages.push(
      "You need to agree to the terms and conditions to continue"
    );
  }

  return errorMessages;
};
