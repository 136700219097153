import { React, useEffect, useState } from "react";
import "../styles/box.css";
import { Button } from "primereact/button";
import supabase from "../config/supabase";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { getCurrentUserFromSupabase } from "@db/currentUser.js";
import { DeveloperMode } from "@config/DeveloperMode.js";

export default function PasswordReset() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [canResetPassword, setCanResetPassword] = useState(false);
  const [successBoxVisible, setSuccessBoxVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");

  const nav = useNavigate();

  useEffect(() => {
    const checkIfStatusIsPasswordRecovery = async () => {
      supabase.auth.onAuthStateChange(async (event, session) => {

        if (session === null && event !== "PASSWORD_RECOVERY") {
          console.log("Go nack to home page");
          return;
        }
      });
    };
    checkIfStatusIsPasswordRecovery();
  });

  const resetPassword = async () => {
    setCanResetPassword(true);
    const user = await getCurrentUserFromSupabase();
    if (password !== passwordRepeat) {
      console.log(password + " " + passwordRepeat);
      setErrorMessages("Passwords do not match");
      setIsError(true);
      return;
    } else if (password.length < 6) {
      setErrorMessages("Password must be at least 6 characters long");
      setIsError(true);
      return;
    }
    setIsError(false);
    const { data, error } = await supabase.auth.updateUser({
      email: user.email,
      password: password,
    });
    if (error) alert("There was an error updating your password.");
    if (data) setSuccessBoxVisible(true);
    setSuccessBoxVisible(true);
  };

  return (
    <>
      <section className='center-center'>
        <div className='black-box'>
          <h1 className='container-title' style={{ marginBottom: "30px" }}>
            Password Reset
          </h1>
          <div className='input-group'>
            <label htmlFor='password'>New password</label>
            <input
              type='password'
              onChange={(event) => setPassword(event.target.value)}
            />
            <label htmlFor='password'>Repeat new password</label>
            <input
              type='password'
              onChange={(event) => setPasswordRepeat(event.target.value)}
            ></input>
          </div>
          {isError ? <p className='error-msg'>{errorMessages}</p> : null}
          <Button label='Confirm Reset' onClick={resetPassword} />
        </div>
      </section>
      <Dialog
        header='Password Reset'
        visible={successBoxVisible}
        style={{ width: "50vw" }}
        modal={true}
        onHide={() => nav("/profile")}
      >
        <p>
          You have successfully reset your password... Please don't forget it
          again or else we recommend the magic link method :){" "}
        </p>
        <Button label='Continue' onClick={() => nav("/profile")} />
      </Dialog>
    </>
  );
}
