import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { getTeamMembers } from "@db/teams.js";

export default function SelectPerson({
  team,
  teamId,
  insertPerson,
  onSelectChange,
  header,
}) {
  const [teamData, setTeamData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTeamMembers(teamId);

      const arrayData = [];
      arrayData.push({
          id: -1,
          name: "Unassigned",
      });
      data.map((u) => {
        const user = {
          id: u.id,
          name: u.first_name + " " + u.last_name,
        };
        arrayData.push(user);
      });

      setTeamData(arrayData);
    };

    if (team) fetchData();
  }, []);

  const handleSelectChange = (e) => {
    setSelectedValue(e.value);
    if (onSelectChange) {
        if(e.value === -1) e.value = null;
      onSelectChange(e.value);
    }
  };

  return (
    <>
      {header && header()}

      <Dropdown
        value={selectedValue}
        onChange={handleSelectChange}
        options={teamData}
        optionLabel='name'
        optionValue='id'
        placeholder='Select person'
      />
    </>
  );
}
