import { TabPanel, TabView } from "primereact/tabview";
import "../styles/admin.css";
import ListAdmin from "@components/admin/ListAdmin.js";
import PaymentAdmin from "@components/admin/PaymentAdmin.js";
import TransportAdmin from "@components/admin/TransportAdmin.js";
import SizesAdmin from "@components/admin/SizesAdmin.js";
import RoomsAdmin from "@components/admin/RoomsAdmin.js";
import { useState, useEffect } from "react";
import { getActiveIndexTab, setActiveIndexTab } from "@utils/activeTab";
import TeamsAdmin from "@components/admin/TeamsAdmin.js";
import SettingsAdmin from "@components/admin/SettingsAdmin.js";
import { getAccessLevelOfCurrentUser } from "@db/admin.js";

export default function Admin() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [accessLevel, setAccessLevel] = useState(null);
  const access_2 = 2;
  const access_3 = 3;

  useEffect(() => {
    getActiveIndexTab("adminActiveIndex", setActiveIndex);
    const fetch = async () => {
      const access = await getAccessLevelOfCurrentUser();
      setAccessLevel(access);
    };
    fetch();
  }, []);

  return (
    <>
      <div className='admin-container'>
        <TabView
          className='admin-tab'
          activeIndex={activeIndex}
          onTabChange={(e) => {
            setActiveIndexTab("adminActiveIndex", e.index);
            setActiveIndex(e.index);
          }}
        >
          <TabPanel header='List'>
            <ListAdmin />
          </TabPanel>
          <TabPanel header='Payment'>
            <PaymentAdmin />
          </TabPanel>
          <TabPanel header='Sizes'>
            <SizesAdmin />
          </TabPanel>
          <TabPanel header='Transit'>
            <TransportAdmin />
          </TabPanel>
          {[
            accessLevel >= access_2 && {
              header: "Room",
              component: <RoomsAdmin />,
            },
            accessLevel >= access_3 && {
              header: "Team",
              component: <TeamsAdmin />,
            },
            accessLevel >= access_3 && {
              header: "Settings",
              component: <SettingsAdmin />,
            },
          ]
            .filter(Boolean)
            .map((tab) => (
              <TabPanel header={tab.header} key={tab.header}>
                {tab.component}
              </TabPanel>
            ))}
        </TabView>
      </div>
    </>
  );
}
