import supabase from "./supabase.js";
import { PriceCalc } from '../utils/PriceCalc.js';

const caseName = "name";
const caseBirthday = "birthday";
const caseEmail = "email";
const casePhone = "phone";
const caseCountry = "country";
const caseChurchMission = "Church/Mission";
const caseMember = "member";
const caseSize = "size";
const caseDates = "dates";
const caseArrivalTransportation = "arrival transportation";
const caseDepartureTransportation = "departure transportation";
const caseAccommodation = "accommodation";
const casePaymentMethod = "payment method";
const casePaymentAmount = "payment amount";
const casePaymentComment = "payment comment";
const caseTeam = "team";
const caseRoom = "room";

const caseTotalAmount = "total amount";
const casePaymentGoal = "payment goal";
const casePaymentStatus = "payment status";
const caseShirtSize = "total shirt size";
const tableName = "users";

export const SelectAllUserData = async (field) => {
  switch (field) {
    case caseName: {
      const { data, error } = await supabase
        .from(tableName)
        .select(`first_name, last_name`);
      if (error) console.log(error);
      return [data[0].first_name, data[0].last_name];
    }
    case caseTotalAmount: {
      const { data, error } = await supabase
        .from(tableName)
        .select(`payment_amount`);
      if (error) console.log(error);
      let total = 0;
      for (let i = 0; i < data.length; i++) {
        total += data[i].payment_amount;
      }
      return total;
    }
    case casePaymentGoal: {
      const { data, error } = await supabase
        .from(tableName)
        .select(`birthday, payment_method`);
      if (error) console.log(error);
      let total = 0;
      for (let i = 0; i < data.length; i++) {
        //Add goal price
        total += PriceCalc(data[i].birthday, data[i].payment_method)[1];
      }
      return total;
    }
    case casePaymentStatus: {
      const { data, error } = await supabase
        .from(tableName)
        .select(`payment_amount, birthday, payment_method`);
      if (error) console.log(error);
      return data;
    }
    case caseShirtSize: {
      const { data, error } = await supabase
        .from(tableName)
        .select(`size, first_name, last_name,team,team`);
      if (error) console.log(error);
      return data;
    }
    case caseRoom: {
      const { data, error } = await supabase
        .from(tableName)
        .select(`room, first_name, last_name`);
      if (error) console.log(error);
      return data;
    }
    case caseEmail: {
      const { data, error } = await supabase
        .from(tableName)
        .select(`email, first_name, last_name`);
      if (error) console.log(error);
      return data;
    }
    case "all": {
      const { data, error } = await supabase.from(tableName).select();
      if (error) console.log(error);
      return data;
    }
    case "name-uuid": {
      const { data, error } = await supabase.from(tableName).select(`id, first_name, last_name`);
      if (error) console.log(error);
      return data;
    }
    default: {
      const { data, error } = await supabase.from(tableName).select();
      if (error) console.log(error);
      return data;
    }
  }
};

export const selectSpecificUserData = async (field, filter) => {
  if (filter === undefined) return null;
  switch (field) {
    case caseRoom: {
      const { data, error } = await supabase
        .from(tableName)
        .select(`room, first_name, last_name`)
        .eq('room', filter);
      if (error) console.log(error);
      return data;
    } default: {
      const { data, error } = await supabase.from(tableName).select();
      if (error) console.log(error);
      return data;
    }
  }
}

export const SelectUserData = async () => {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  const { data, error } = await supabase
    .from(tableName)
    .select()
    .eq("id", user.id);
  if (error) console.log(error);
  return data[0];
};

export const UpdateData = (uuid, tableName, field, newValue, newValue2) => {
  const update = async () => {
    switch (field) {
      case caseName: {
        const { error } = await supabase
          .from(tableName)
          .update({
            first_name: newValue,
            last_name: newValue2,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue, newValue2];
      }
      case caseBirthday: {
        const date = new Date(newValue);
        date.setDate(date.getDate() + 1); // Add 1 day to the date cause of timezone
        const { error } = await supabase
          .from(tableName)
          .update({
            birthday: date,
          })
          .eq("id", uuid);
        // LS.setData(caseBirthday, newValue);
        if (error) console.log(error);
        return [newValue];
      }
      case casePhone: {
        const { error } = await supabase
          .from(tableName)
          .update({
            phone: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseCountry: {
        const { error } = await supabase
          .from(tableName)
          .update({
            country: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseChurchMission: {
        if (newValue === "Other") {
          newValue = newValue2;
        }
        const { error } = await supabase
          .from(tableName)
          .update({
            church_mission: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseMember: {
        const { error } = await supabase
          .from(tableName)
          .update({
            member: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseSize: {
        const { error } = await supabase
          .from(tableName)
          .update({
            size: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseDates: {
        const { error } = await supabase
          .from(tableName)
          .update({
            dates: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseArrivalTransportation: {
        const { error } = await supabase
          .from(tableName)
          .update({
            arrival_transportation: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseDepartureTransportation: {
        const { error } = await supabase
          .from(tableName)
          .update({
            departure_transportation: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseAccommodation: {
        const { error } = await supabase
          .from(tableName)
          .update({
            accommodation: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case casePaymentMethod: {
        const { error } = await supabase
          .from(tableName)
          .update({
            payment_method: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case casePaymentAmount: {
        const { error } = await supabase
          .from(tableName)
          .update({
            payment_amount: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseTeam: {
        const { error } = await supabase
          .from(tableName)
          .update({
            team: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      case caseRoom: {
        const { error } = await supabase
          .from(tableName)
          .update({
            room: newValue,
          })
          .eq("id", uuid);
        if (error) console.log(error);
        return [newValue];
      }
      default:
        break;
    }
  };
  update();
};

export const SelectTeamData = async () => {
  const { data, error } = await supabase.from("teams").select();
  if (error) console.log(error);
  return data;
};

export const SelectRoomData = async () => {
  const { data, error } = await supabase.from("rooms").select();
  if (error) console.log(error);
  return data;
};
