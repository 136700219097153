import supabase from '../config/supabase.js';


export const updateDataUsers = async (uuid, column, data) => {
    const { error } = await supabase
        .from("users")
        .update(column,data)
        .eq('id', uuid);
    if (error) {
        console.error(error);
        return null;
    }
    return true;
    }