import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useState, useEffect } from "react";
import {formatLocalDateUS} from "@utils/dateTime.js";

export default function ConfirmBox({
  visible,
  first_name,
  last_name,
  phone,
  birthday,
  gender,
  country,
  church,
  selected_dates,
  member,
  shirt_size,
  arrival_transportation,
  departure_transportation,
  accommodation,
  submit,
  pricingInfo,
  onHide,
}) {
  useEffect(() => {
    if (selected_dates) {
      selectDateConverter(selected_dates);
    }
    if (birthday) {
      setBirthdayString(formatLocalDateUS(birthday));
    }
  }); // Dependency array, so this runs whenever selected_dates changes

  const [dates, setDates] = useState("");
  const [isAttendingAll, setIsAttendingAll] = useState(false);
  const [birthdayString, setBirthdayString] = useState("");

  const selectDateConverter = (dateArray) => {
    // Ensure dateArray is a string
    if (dateArray.length === 5) return setIsAttendingAll(true);

    let dates_ = "";
    dateArray.map((date) => {
      dates_ += date + " ";
    });

    setDates(dates_);
  };

  return (
    <>
      <Dialog
        header='Please check the information before confirming as this cannot be changed.'
        visible={visible}
        onHide={onHide}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      >
        <ul>
          <li>
            Name: {first_name} {last_name}
          </li>
          <li>Phone: {phone}</li>
          <li>Birthday: {birthdayString}</li>
          <li>Gender: {gender}</li>
          <li>Country: {country}</li>
          <li>Church: {church}</li>
          <li>Member: {member ? "Yes" : "No"}</li>
          <li>Shirt Size: {shirt_size}</li>
          <li>Arrival Transportation: {arrival_transportation}</li>
          <li>Departure Transportation: {departure_transportation}</li>
          {isAttendingAll ? (
            <li>Selected Dates: Attending all days</li>
          ) : (
            <>
              <li>Selected Dates: {dates}</li>
              <li>Selected Dates: {}</li>
            </>
          )}
          <li>Accommodation: {accommodation}</li>
        </ul>
        <div className="p-black p-result">
        <p>Price type: {pricingInfo.name}</p>
        <p>Price: {pricingInfo.price} DKK</p>
        </div>
        <Button
          label='Confirm'
          icon='pi pi-check'
          onClick={() => {
            submit(true);
            onHide();
          }}
        />
        <Button
          label='Cancel'
          className='p-button-danger'
          icon='pi pi-times
          '
          onClick={() => {
            onHide();
          }}
        />
      </Dialog>
    </>
  );
}
