import { React, useState, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";

// https://gist.github.com/good-idea/cfe88c8ddf5656d8c779e9448b4a212a
//https://www.fincher.org/Utilities/CountryLanguageList.shtml
let selectedCountry = "not set";

function CountriesList({onSelectChange}) {
  const [country, setCountry] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(null);

  const search = (event) => {
    let query = event.query;
    let _filteredCities = [];

    for (let country of groupedCities) {
      let filteredItems = country.items.filter(
        (item) => item.label.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );

      if (filteredItems && filteredItems.length) {
        _filteredCities.push({ ...country, ...{ items: filteredItems } });
      }
    }
    setFilteredCountries(_filteredCities);
  };

  const handleSelectChange = (e) => {
	if (e.value === null) return;
    setCountry(e.value); // Update country with the label of the selected item
    onSelectChange(e.value); // Call the callback function with the selected value
  };


  const groupedCities = [
    {
      label: "General",
      code: "G",
      items: [
        { label: "Denmark 🇩🇰", value: "DK" },
        { label: "Sweden 🇸🇪", value: "SE" },
        { label: "Spain 🇪🇸", value: "ES" },
        { label: "Netherlands 🇳🇱", value: "NL" },
        { label: "United States of America 🇺🇸", value: "US" },
      ],
    },
    {
      label: "Other",
      code: "Other",
      items: [
        { label: "Afghanistan 🇦🇫", value: "AF" },
        { label: "Albania 🇦🇱", value: "AL" },
        { label: "Algeria 🇩🇿", value: "DZ" },
        { label: "Andorra 🇦🇩", value: "AD" },
        { label: "Angola 🇦🇴", value: "AO" },
        { label: "Antigua and Barbuda 🇦🇬", value: "AG" },
        { label: "Argentina 🇦🇷", value: "AR" },
        { label: "Armenia 🇦🇲", value: "AM" },
        { label: "Australia 🇦🇺", value: "AU" },
        { label: "Austria 🇦🇹", value: "AT" },
        { label: "Azerbaijan 🇦🇿", value: "AZ" },
        { label: "Bahrain 🇧🇭", value: "BH" },
        { label: "Bangladesh 🇧🇩", value: "BD" },
        { label: "Barbados 🇧🇧", value: "BB" },
        { label: "Belarus 🇧🇾", value: "BY" },
        { label: "Belgium 🇧🇪", value: "BE" },
        { label: "Belize 🇧🇿", value: "BZ" },
        { label: "Benin 🇧🇯", value: "BJ" },
        { label: "Bhutan 🇧🇹", value: "BT" },
        { label: "Bolivia 🇧🇴", value: "BO" },
        { label: "Bosnia and Herzegovina 🇧🇦", value: "BA" },
        { label: "Botswana 🇧🇼", value: "BW" },
        { label: "Brazil 🇧🇷", value: "BR" },
        { label: "Brunei 🇧🇳", value: "BN" },
        { label: "Bulgaria 🇧🇬", value: "BG" },
        { label: "Burkina Faso 🇧🇫", value: "BF" },
        { label: "Burundi 🇧🇮", value: "BI" },
        { label: "Cambodia 🇰🇭", value: "KH" },
        { label: "Cameroon 🇨🇲", value: "CM" },
        { label: "Canada 🇨🇦", value: "CA" },
        { label: "Central African Republic 🇨🇫", value: "CF" },
        { label: "Chad 🇹🇩", value: "TD" },
        { label: "Chile 🇨🇱", value: "CL" },
        { label: "China 🇨🇳", value: "CN" },
        { label: "Colombia 🇨🇴", value: "CO" },
        { label: "Costa Rica 🇨🇷", value: "CR" },
        { label: "Croatia 🇭🇷", value: "HR" },
        { label: "Cuba 🇨🇺", value: "CU" },
        { label: "Cyprus 🇨🇾", value: "CY" },
        { label: "Czech Republic 🇨🇿", value: "CZ" },
        { label: "Democratic Republic of the Congo 🇨🇩", value: "CD" },
        { label: "Djibouti 🇩🇯", value: "DJ" },
        { label: "Dominica 🇩🇲", value: "DM" },
        { label: "Dominican Republic 🇩🇴", value: "DO" },
        { label: "East Timor 🇹🇱", value: "TL" },
        { label: "Ecuador 🇪🇨", value: "EC" },
        { label: "Egypt 🇪🇬", value: "EG" },
        { label: "El Salvador 🇸🇻", value: "SV" },
        { label: "Equatorial Guinea 🇬🇶", value: "GQ" },
        { label: "Eritrea 🇪🇷", value: "ER" },
        { label: "Estonia 🇪🇪", value: "EE" },
        { label: "Ethiopia 🇪🇹", value: "ET" },
        { label: "Falkland Islands 🇫🇰", value: "FK" },
        { label: "Fiji 🇫🇯", value: "FJ" },
        { label: "Finland 🇫🇮", value: "FI" },
        { label: "France 🇫🇷", value: "FR" },
        { label: "Gabon 🇬🇦", value: "GA" },
        { label: "Gambia 🇬🇲", value: "GM" },
        { label: "Georgia 🇬🇪", value: "GE" },
        { label: "Germany 🇩🇪", value: "DE" },
        { label: "Ghana 🇬🇭", value: "GH" },
        { label: "Greece 🇬🇷", value: "GR" },
        { label: "Greenland 🇬🇱", value: "GL" },
        { label: "Guatemala 🇬🇹", value: "GT" },
        { label: "Guinea 🇬🇳", value: "GN" },
        { label: "Guinea Bissau 🇬🇼", value: "GW" },
        { label: "Guyana 🇬🇾", value: "GY" },
        { label: "Haiti 🇭🇹", value: "HT" },
        { label: "Honduras 🇭🇳", value: "HN" },
        { label: "Hungary 🇭🇺", value: "HU" },
        { label: "Iceland 🇮🇸", value: "IS" },
        { label: "India 🇮🇳", value: "IN" },
        { label: "Indonesia 🇮🇩", value: "ID" },
        { label: "Iran 🇮🇷", value: "IR" },
        { label: "Iraq 🇮🇶", value: "IQ" },
        { label: "Ireland 🇮🇪", value: "IE" },
        { label: "Israel 🇮🇱", value: "IL" },
        { label: "Italy 🇮🇹", value: "IT" },
        { label: "Ivory Coast 🇨🇮", value: "CI" },
        { label: "Jamaica 🇯🇲", value: "JM" },
        { label: "Japan 🇯🇵", value: "JP" },
        { label: "Jordan 🇯🇴", value: "JO" },
        { label: "Kazakhstan 🇰🇿", value: "KZ" },
        { label: "Kenya 🇰🇪", value: "KE" },
        { label: "Kingdom of Eswatini 🇸🇿", value: "SZ" },
        { label: "Kingdom of Lesotho 🇱🇸", value: "LS" },
        { label: "Kiribati 🇰🇮", value: "KI" },
        { label: "Kosovo 🇽🇰", value: "XK" },
        { label: "Kuwait 🇰🇼", value: "KW" },
        { label: "Kyrgyzstan 🇰🇬", value: "KG" },
        { label: "Laos 🇱🇦", value: "LA" },
        { label: "Latvia 🇱🇻", value: "LV" },
        { label: "Lebanon 🇱🇧", value: "LB" },
        { label: "Liberia 🇱🇷", value: "LR" },
        { label: "Libya 🇱🇾", value: "LY" },
        { label: "Liechtenstein 🇱🇮", value: "LI" },
        { label: "Lithuania 🇱🇹", value: "LT" },
        { label: "Luxembourg 🇱🇺", value: "LU" },
        { label: "Madagascar 🇲🇬", value: "MG" },
        { label: "Malawi 🇲🇼", value: "MW" },
        { label: "Malaysia 🇲🇾", value: "MY" },
        { label: "Maldives 🇲🇻", value: "MV" },
        { label: "Mali 🇲🇱", value: "ML" },
        { label: "Malta 🇲🇹", value: "MT" },
        { label: "Marshall Islands 🇲🇭", value: "MH" },
        { label: "Mauritania 🇲🇷", value: "MR" },
        { label: "Mauritius 🇲🇺", value: "MU" },
        { label: "Mexico 🇲🇽", value: "MX" },
        { label: "Micronesia 🇫🇲", value: "FM" },
        { label: "Moldova 🇲🇩", value: "MD" },
        { label: "Monaco 🇲🇨", value: "MC" },
        { label: "Mongolia 🇲🇳", value: "MN" },
        { label: "Montenegro 🇲🇪", value: "ME" },
        { label: "Morocco 🇲🇦", value: "MA" },
        { label: "Mozambique 🇲🇿", value: "MZ" },
        { label: "Myanmar 🇲🇲", value: "MM" },
        { label: "Namibia 🇳🇦", value: "NA" },
        { label: "Nauru 🇳🇷", value: "NR" },
        { label: "Nepal 🇳🇵", value: "NP" },
        { label: "New Caledonia 🇳🇨", value: "NC" },
        { label: "New Zealand 🇳🇿", value: "NZ" },
        { label: "Nicaragua 🇳🇮", value: "NI" },
        { label: "Niger 🇳🇪", value: "NE" },
        { label: "Nigeria 🇳🇬", value: "NG" },
        { label: "North Korea 🇰🇵", value: "KP" },
        { label: "North Macedonia 🇲🇰", value: "MK" },
        { label: "Norway 🇳🇴", value: "NO" },
        { label: "Oman 🇴🇲", value: "OM" },
        { label: "Pakistan 🇵🇰", value: "PK" },
        { label: "Palau 🇵🇼", value: "PW" },
        { label: "Panama 🇵🇦", value: "PA" },
        { label: "Papua New Guinea 🇵🇬", value: "PG" },
        { label: "Paraguay 🇵🇾", value: "PY" },
        { label: "Peru 🇵🇪", value: "PE" },
        { label: "Philippines 🇵🇭", value: "PH" },
        { label: "Poland 🇵🇱", value: "PL" },
        { label: "Portugal 🇵🇹", value: "PT" },
        { label: "Qatar 🇶🇦", value: "QA" },
        { label: "Republic of the Congo 🇨🇬", value: "CG" },
        { label: "Romania 🇷🇴", value: "RO" },
        { label: "Russia 🇷🇺", value: "RU" },
        { label: "Rwanda 🇷🇼", value: "RW" },
        { label: "Saint Kitts and Nevis 🇰🇳", value: "KN" },
        { label: "Saint Lucia 🇱🇨", value: "LC" },
        { label: "Saint Vincent and the Grenadines 🇻🇨", value: "VC" },
        { label: "Samoa 🇼🇸", value: "WS" },
        { label: "San Marino 🇸🇲", value: "SM" },
        { label: "Sao Tome and Principe 🇸🇹", value: "ST" },
        { label: "Saudi Arabia 🇸🇦", value: "SA" },
        { label: "Senegal 🇸🇳", value: "SN" },
        { label: "Serbia 🇷🇸", value: "RS" },
        { label: "Seychelles 🇸🇨", value: "SC" },
        { label: "Sierra Leone 🇸🇱", value: "SL" },
        { label: "Singapore 🇸🇬", value: "SG" },
        { label: "Slovakia 🇸🇰", value: "SK" },
        { label: "Slovenia 🇸🇮", value: "SI" },
        { label: "Solomon Islands 🇸🇧", value: "SB" },
        { label: "Somalia 🇸🇴", value: "SO" },
        { label: "South Africa 🇿🇦", value: "ZA" },
        { label: "South Korea 🇰🇷", value: "KR" },
        { label: "South Sudan 🇸🇸", value: "SS" },
        { label: "Spain 🇪🇸", value: "ES" },
        { label: "Sri Lanka 🇱🇰", value: "LK" },
        { label: "Sudan 🇸🇩", value: "SD" },
        { label: "Suriname 🇸🇷", value: "SR" },
        { label: "Sweden 🇸🇪", value: "SE" },
        { label: "Switzerland 🇨🇭", value: "CH" },
        { label: "Syria 🇸🇾", value: "SY" },
        { label: "Taiwan 🇹🇼", value: "TW" },
        { label: "Tajikistan 🇹🇯", value: "TJ" },
        { label: "Tanzania 🇹🇿", value: "TZ" },
        { label: "Thailand 🇹🇭", value: "TH" },
        { label: "Togo 🇹🇬", value: "TG" },
        { label: "Tonga 🇹🇴", value: "TO" },
        { label: "Trinidad and Tobago 🇹🇹", value: "TT" },
        { label: "Tunisia 🇹🇳", value: "TN" },
        { label: "Turkey 🇹🇷", value: "TR" },
        { label: "Turkmenistan 🇹🇲", value: "TM" },
        { label: "Tuvalu 🇹🇻", value: "TV" },
        { label: "Uganda 🇺🇬", value: "UG" },
        { label: "Ukraine 🇺🇦", value: "UA" },
        { label: "United Arab Emirates 🇦🇪", value: "AE" },
        { label: "United Kingdom 🇬🇧", value: "GB" },
        { label: "United States of America 🇺🇸", value: "US" },
        { label: "Uruguay 🇺🇾", value: "UY" },
        { label: "Uzbekistan 🇺🇿", value: "UZ" },
        { label: "Vanuatu 🇻🇺", value: "VU" },
        { label: "Vatican City 🇻🇦", value: "VA" },
        { label: "Venezuela 🇻🇪", value: "VE" },
        { label: "Vietnam 🇻🇳", value: "VN" },
        { label: "Yemen 🇾🇪", value: "YE" },
        { label: "Zambia 🇿🇲", value: "ZM" },
        { label: "Zimbabwe 🇿🇼", value: "ZW" },
      ],
    },
  ];

  return (
    // <AutoComplete
    //   field='label'
    //   value={country}
    //   suggestions={filteredCountries}
    //   completeMethod={search}
    //   onChange={handleSelectChange}
    //   optionGroupLabel='label'
    //   optionGroupChildren='items'
    //   placeholder='Select a Country'
    //   forceSelection
    //   dropdown
    // />
    <Dropdown
      value={country}
      options={groupedCities}
      optionLabel='label'
      optionGroupLabel='label'
      optionGroupChildren='items'
      onChange={handleSelectChange}
      placeholder='Select country'
      filter
    />
  );
}

export { CountriesList };
