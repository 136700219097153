import { useEffect, useState } from "react";
import Display from "@components/display/Display.js";
import { getRoomsInfoDataUser, getRoomNamebyId, getRooms } from "@db/rooms.js";

export default function RoomsDisplay({ displayData, updateData }) {
  const [groupedData, setGroupedData] = useState([]);

  useEffect(() => {
    const fetchRoomData = async () => {
      const data = await getRoomsInfoDataUser();
      const RoomGroup = await getRooms(data);
      setGroupedData(RoomGroup);
    };

    // Set grouped data to state
    if (displayData) {
      // Ensure displayData is truthy before setting groupedData
      setGroupedData(displayData);
    } else fetchRoomData();
  }, []); // Trigger effect when displayData changes

  useEffect(() => {
    // Update groupedData when updateData changes
    if (updateData) {
      if (updateData.room_id === null) updateData.room_id = 0;
      setGroupedData((prevData) => {
        let userRemoved = false; // Flag to track if user has been removed
        let newData = prevData.map((room) => {
          if (!userRemoved) {
            const updatedData = room.list.filter(
              (user) => user.id !== updateData.id
            );
            if (updatedData.length !== room.list.length) {
              userRemoved = true;
              return {
                ...room,
                list: updatedData,
              };
            }
          }
          // If room_id matches updateData.room_id, add user to the data list
          if (room.id === updateData.room_id) {
            return {
              ...room,
              list: [...room.list, updateData],
            };
          }
          return room;
        });

        return newData;
      });
    }
  }, [updateData]);

  // Render Display component only if groupedData is truthy
  return groupedData.length > 0 && <Display data={groupedData} />;
}
