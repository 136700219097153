import React from "react";
import ReactPlayer from "react-player";
//https://www.npmjs.com/package/react-player - npm package
//https://cookpete.github.io/react-player/ - Demo

export default function VideoPlayer({ url }) {
  return (
    <div className='box-space-horizontal box-center'>
      <ReactPlayer url={url} width='75%' controls />
    </div>
  );
}

export const VideoBackground = ({ url }) => {
  return (
    <>
      <section className='fixed-background'>
        <ReactPlayer url={url} width="100" playing muted loop />
      </section>
    </>
  );
};
